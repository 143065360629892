import React, { ReactChild, ReactNodeArray } from 'react'
import { FormattedMessage } from 'react-intl'

interface IInfoItem {
  first: string
  second: any
  variant?: string
  l?: number
  r?: number
  className?: string
}

const InfoItem: React.FC<IInfoItem> = ({ variant, l, r, first, second }) => {
  const className = variant ?? 'default'

  const classNameBg = (item: any) => {
    if (second === 'BUY') {
      return 'bg-buy badge badge-pill'
    } else if (second === 'Buy') {
      return 'bg-buy badge badge-pill'
    } else if (second === 'Sell') {
      return 'bg-sell badge badge-pill'
    } else if (second === 'SELL') {
      return 'bg-sell badge badge-pill'
    }
  }

  return (
    <div className={`row info-item ${className}`}>
      <div className={l ? `col-${l}` : 'col-6'}>
        {/*
 // @ts-ignore */}
        <span className="mr-4 nowrap">{first ? <FormattedMessage id={first}>{(txt: any) => `${txt}:`}</FormattedMessage> : ''}</span>
      </div>
      <div className={r ? `col-${r}` : 'col-6'} style={{ fontSize: classNameBg(second) ? '12px' : '13px' }}>
        {typeof second === 'string' ? <span className={classNameBg(second)}>{second}</span> : second}
      </div>
    </div>
  )
}

export default InfoItem
