import { AppAccordion } from '@t4b/core/lib'
import React, { useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { ConnectionString, RmqSettings } from '../../entity/configuration'
import { convertPlatformFields, Mt4PlatformEntity } from '../../entity/platforms'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useDefaultDB } from '../../hooks/useDefaultDbSettings'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchAddPlatform, modifyPlatform } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, passwordInput, textInput } from '../../utils/controls'
import { rmqSchema } from '../../utils/schema-utils'
import DbConnectionEditor from '../configuration/system/DbConnectionEditor'

interface IMT4PlatformEdit extends IGatewayProp {
  data: any
  isValidParent?(): boolean
}

const MT4PlatformEdit: React.FC<IMT4PlatformEdit> = ({ data: { item, type, port }, gateway, gatewayName, isValidParent }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new Mt4PlatformEntity(item), Mt4PlatformEntity.schema(type))
  const [connectionString, setConnectionString, connTouched, setConnTouched, connErrors, isConnValid] = useFormValidation(new ConnectionString(item.ConnectionString), ConnectionString.schema(type))
  const [rmq, setRmq, rmqTouched, setRmqTouched, rmqErrors, isRmqValid] = useFormValidation(new RmqSettings(item.RMQ), rmqSchema(type))
  const dispatch = useDispatch()
  const ref = useRef<any>(null)
  const dbRef = useRef<any>(null)
  const rmqRef = useRef<any>(null)

  useEffect(() => {
    setInputState({
      ...inputState,
      Name: item.Name,
      FileStorePath: item.FileStorePath,
      FileLogPath: item.FileLogPath,
    })
    setConnectionString({
      ...connectionString,
      Database: item.ConnectionString.Database,
    })
  }, [item.Name]) // eslint-disable-line react-hooks/exhaustive-deps

  const [isDefaultSettings, setDefaultDb] = useDefaultDB(type, connectionString, setConnectionString, gatewayName, inputState)

  const dbEditor = <DbConnectionEditor type={type} state={connectionString} setState={setConnectionString} touched={connTouched} setTouched={setConnTouched} errors={connErrors} isDefaultSettings={isDefaultSettings} setIsDefaultSettings={setDefaultDb} />

  const newPort = port.filter((elem: any) => elem !== item.SocketAcceptPort)
  const alreadyExist = () => {
    if (port.map(String).includes(String(inputState.SocketAcceptPort)) && type === 'add') {
      errors.SocketAcceptPort = true
      return 'Аlready exists'
    }

    if (newPort.map(String).includes(String(inputState.SocketAcceptPort))) {
      errors.SocketAcceptPort = true
      return 'Аlready exists'
    }

    return ''
  }

  const editPlatform = buildControlsExtTwoPerLine(
    [
      textInput('Mt4ServerName').disabled(type !== 'add'),
      textInput('OrderRestoreInterval'),
      checkboxInput('ConfirmRequestsEnable'),
      checkboxInput('SSLEnable'),
      checkboxInput('UseLocalTime'),
      textInput('Mt4Server'),
      textInput('Mt4ServerPort'),
      textInput('Mt4Login'),
      passwordInput('Mt4Password', type === 'add' ? 'setup' : 'change'),
      textInput('SocketAcceptPort').errorMessage(alreadyExist()),
      textInput('FileStorePath'),
      textInput('FileLogPath'),
    ],
    inputState,
    setInputState,
    'platform.mt4',
    touched,
    setTouched,
    errors,
  )

  const rmqSettings = buildControlsExtTwoPerLine(
    [textInput('RMQHost'), textInput('RMQPort'), textInput('RMQVirtualHost'), textInput('RMQLogin'), passwordInput('RMQPassword', type === 'add' ? 'setup' : 'change'), textInput('RMQExchange'), textInput('RMQQueue')],
    rmq,
    setRmq,
    'rmq',
    rmqTouched,
    setRmqTouched,
    rmqErrors,
  )

  const handleSave = () => {
    const cond1 = isValid()
    let cond2 = isConnValid()
    const cond3 = isRmqValid()

    if (isValidParent) {
      let validName = isValidParent()
      if (!validName) {
        return
      }
    }

    if (!cond1) {
      ref?.current?.open()
    }

    if (!cond2) {
      dbRef?.current?.open()
    }

    if (!cond3) {
      rmqRef?.current?.open()
    }

    if (!cond1 || !cond2 || !cond3) {
      return
    }

    dispatch(hideRightBar())
    if (type === 'add') {
      dispatch(
        fetchAddPlatform({
          action: 'add',
          params: { Gateway: gateway.Name },
          body: {
            ...convertPlatformFields(inputState),
            RMQ: rmq,
            ConnectionString: connectionString,
          },
        }),
      )
    } else if (type === 'modify') {
      dispatch(
        modifyPlatform({
          action: 'modify',
          params: { Gateway: gateway.Name },
          body: {
            ...convertPlatformFields(inputState),
            RMQ: rmq,
            ConnectionString: connectionString,
          },
        }),
      )
    }
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.edit.mt4" values={{ Type: type === 'add' ? 'MT4' : `Modify ${item.Name} (MT4)` }} />,
          item: editPlatform,
        }}
        ref={ref}
        render={ref?.current}
        isHidden={false}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.rmq-settings" />,
          item: rmqSettings,
        }}
        render={ref?.current}
        ref={rmqRef}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.conn-string" />,
          item: dbEditor,
        }}
        ref={dbRef}
      />
      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
}

export default withGateway(MT4PlatformEdit)
