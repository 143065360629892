import { IVolumeDistribution } from './volume-distribution'
import * as yup from 'yup'

export class CeActiveEntity {
  Direction: string
  Symbol: string
  Distribution: IVolumeDistribution
  Volume: number
  RemainingVolume: number
  FilledVolume: number
  Timeout: number
  MaxOrderVolume: number
  Finish: number
  Id: number

  constructor(item: CeActiveEntity) {
    this.Direction = item.Direction
    this.Symbol = item.Symbol
    this.Distribution = item.Distribution
    this.Volume = item.Volume
    this.RemainingVolume = item.RemainingVolume
    this.FilledVolume = item.FilledVolume
    this.Timeout = item.Timeout
    this.MaxOrderVolume = item.MaxOrderVolume
    this.Finish = item.Finish
    this.Id = item.Id
  }
}

export class CeFailedEntity {
  Id: number
  Direction: string
  Symbol: string
  Lp: string
  Volume: number
  RemainingVolume: number
  FilledVolume: number
  Finish: number
  Distribution: IVolumeDistribution

  constructor(item: CeFailedEntity) {
    this.Id = item.Id
    this.Direction = item.Direction
    this.Symbol = item.Symbol
    this.Lp = item.Lp
    this.Volume = item.Volume
    this.RemainingVolume = item.RemainingVolume
    this.FilledVolume = item.FilledVolume
    this.Finish = item.Finish
    this.Distribution = item.Distribution
  }
}

export class CeHistoryEntity {
  Symbol: string
  Direction: string
  Volume: number
  Pool: string
  Initiator?: string
  StartTime: number
  FinishTime: number
  Distribution: IVolumeDistribution

  constructor(item: CeHistoryEntity) {
    this.Symbol = item.Symbol
    this.Direction = item.Direction
    this.Volume = item.Volume
    this.Pool = item.Pool
    this.Initiator = item?.Initiator
    this.StartTime = item.StartTime
    this.FinishTime = item.FinishTime
    this.Distribution = item.Distribution
  }
}

export class BbookHedged {
  Symbol: string
  Direction: string
  Volume: number
  Pool: string

  StartTime: number
  FinishTime: number
  Distribution: IVolumeDistribution

  constructor(item: CeHistoryEntity) {
    this.Symbol = item.Symbol
    this.Direction = item.Direction
    this.Volume = item.Volume
    this.Pool = item.Pool

    this.StartTime = item.StartTime
    this.FinishTime = item.FinishTime
    this.Distribution = item.Distribution
  }
}

export class BbookHedgedValue {
  Symbol: string
  Threshold: number | null
  Volume: number
  LastUpdate: number

  constructor(item: any) {
    this.Symbol = item.Symbol
    this.Threshold = item.Threshold ?? 'N/A'
    this.Volume = item.Volume
    this.LastUpdate = item.LastUpdate
  }
}

export class CeRuleEntity {
  Id: number
  Symbol: string
  MaxOrderVolume: number
  Duration: number
  Timeout: number

  static schema = {
    Symbol: yup.string().required(),
    MaxOrderVolume: yup.number().required(),
    Duration: yup.number().required(),
    Timeout: yup.number().integer().min(100).required(),
  }

  constructor(item?: CeRuleEntity) {
    if (item) {
      this.Id = item.Id
      this.Symbol = item.Symbol
      this.MaxOrderVolume = item.MaxOrderVolume
      this.Duration = item.Duration
      this.Timeout = item.Timeout
    } else {
      this.Id = 0
      this.Symbol = ''
      this.MaxOrderVolume = 0
      this.Duration = 0
      this.Timeout = 100
    }
  }
}
