import { LpAlpaca, LpEntity, LpSymbol, HedgingBBook, Binance, Laverate } from '../../entity/system-settings'
import { Action } from '../actions/actions'

class SysLpsState {
  lps: LpEntity[]
  alpacaLps: LpAlpaca[]
  types: string[]
  symbolMap: any
  hedgingBBook: any[]
  ApiBasedLps: any[]
  SyntheticLps: any[]
  SyntheticIndices: any[]
  SymbolMapSynthetic: any
  SymbolMapSyntheticIndex: any
  VolumeTransformers: any[]
  volumeTransformerCumulative: any[]
  VolumeTransformerSymbolMap: any[]
  onChange: boolean
  totalCount: number
  SyntheticIndexBid: any
  SyntheticIndexAsk: any
  SyntheticIndexMessage: any

  constructor() {
    this.SyntheticIndexMessage = ''
    this.SyntheticIndexBid = 0
    this.SyntheticIndexAsk = 0
    this.lps = []
    this.alpacaLps = []
    this.types = []
    this.symbolMap = {
      data: [],
      totalCount: 0,
    }
    this.hedgingBBook = []
    this.ApiBasedLps = []
    this.SyntheticLps = []
    this.SymbolMapSynthetic = []
    this.VolumeTransformers = []
    this.volumeTransformerCumulative = []
    this.VolumeTransformerSymbolMap = []
    this.onChange = true
    this.totalCount = 0
    this.SyntheticIndices = []
    this.SymbolMapSyntheticIndex = []
  }
}

function sysLpsReducer(state = new SysLpsState(), action: any) {
  switch (action.type) {
    case Action.GotSysLps:
      return {
        ...state,
        lps: action.data.GenericLps.map((item: any) => new LpEntity(item)),
        ApiBasedLps: action.data.ApiBasedLps.map((item: any) => {
          if (item.Type === 'AlpacaMarkets') {
            return new LpAlpaca(item)
          } else if (item.Type === 'Laverate') {
            return new Laverate(item)
          } else {
            return new Binance(item)
          }
        }),
        hedgingBBook: action.data.BBooksHedged.map((item: any) => new HedgingBBook(item)),
        SyntheticLps: action.data.SyntheticLps,
        SyntheticIndices: action.data.SyntheticIndices ?? [],
        VolumeTransformers: action.data?.VolumeConverters ?? [],
      }
    case Action.GotSysLpSymbolMap:
      return {
        ...state,
        symbolMap: {
          data: action.data.Data.map((item: any) => new LpSymbol(item)),
          totalCount: action.data.TotalCount,
        },
      }

    case Action.SyntheticSymbolsPreview:
      return {
        ...state,
        SyntheticSymbolsBid: action.data.Bid,
        SyntheticSymbolsAsk: action.data.Ask,
        SyntheticSymbolsMessage: action.data.Message,
      }

    case Action.SyntheticSymbolsPreviewDefault:
      return {
        ...state,
        SyntheticSymbolsBid: null,
        SyntheticSymbolsAsk: null,
        SyntheticSymbolsMessage: '',
      }

    case Action.SyntheticIndexPreview:
      return {
        ...state,
        SyntheticIndexBid: action.data.Bid,
        SyntheticIndexAsk: action.data.Ask,
        SyntheticIndexMessage: action.data.Message,
      }

    case Action.SyntheticIndexPreviewDefault:
      return {
        ...state,
        SyntheticIndexBid: null,
        SyntheticIndexAsk: null,
        SyntheticIndexMessage: '',
      }

    case Action.GotSysLpSymbolMapSynthetic:
      return {
        ...state,
        SymbolMapSynthetic: {
          data: action.data.Data,
          totalCount: action.data.TotalCount,
          GlobalSymbolReserved: action.data.ExistingSyntheticSymbols,
        },
      }

    case Action.GotSysLpSymbolMapSyntheticIndex:
      return {
        ...state,
        SymbolMapSyntheticIndex: {
          data: action.data.Data,
          totalCount: action.data.TotalCount,
          GlobalSymbolReserved: action.data.ExistingSyntheticSymbols,
        },
      }

    case Action.GotSysLpBinanceSelect:
      return {
        ...state,
        FuturesAllowedValues: action.data.FuturesAllowedValues,
        StockAllowedValues: action.data.StockAllowedValues,
      }
    case Action.GotLPTypes:
      return {
        ...state,
        types: action.data,
      }
    case Action.GotLPPool:
      return {
        ...state,
        pool: action.data,
      }
    case Action.GotLPPoolSynetic:
      return {
        ...state,
        LPPoolSynetic: action.data,
      }
    case Action.GotVolumeTransformerCumulative:
      return {
        ...state,
        onChange: true,
        volumeTransformerCumulative: action.data,
      }

    case Action.ModifyVolumeTransformerCumulative:
      return {
        ...state,
        onChange: false,
        volumeTransformerCumulative: state.volumeTransformerCumulative.map((item: any) => {
          const newItem = {
            ...action.data,
            Symbol: action.data.Symbol.value,
          }
          if (item.Symbol === action.data.Symbol.value) {
            return newItem
          } else {
            return item
          }
        }),
      }
    case Action.AddVolumeTransformerCumulative:
      const newItem = {
        ...action.data,
        Symbol: action.data.Symbol.value,
      }
      return {
        ...state,
        onChange: false,
        volumeTransformerCumulative: [...state.volumeTransformerCumulative, newItem],
      }
    case Action.DeleteVolumeTransformerCumulative:
      return {
        ...state,
        onChange: false,
        volumeTransformerCumulative: state.volumeTransformerCumulative.filter((item: any) => item.Symbol !== action.data.Symbol),
      }
    case Action.GotVolumeTransformerSymbolMap:
      return {
        ...state,
        VolumeTransformerSymbolMap: action.data.Data,
        totalCount: action.data.TotalCount,
      }
    default:
      return state
  }
}

export function ModifyVolumeTransformerCumulative(data: any) {
  return { type: Action.ModifyVolumeTransformerCumulative, data }
}
export function deleteVolumeTransformerCumulative(data: any) {
  return { type: Action.DeleteVolumeTransformerCumulative, data }
}
export function AddVolumeTransformerCumulative(data: any) {
  return { type: Action.AddVolumeTransformerCumulative, data }
}

export default sysLpsReducer
