import { IColor } from '../components/color-picker'
import { buildMultiselectOptionsFromArray, buildMultiselectOptionsFromEnum } from '../utils/multiselect-utils'
import { IMultiSelectItem } from './multiselect'
import * as yup from 'yup'
import { folderNameSchema, loginsSchema, MaxLength, passwordSchema, portSchema, transformEmptyStringMaxLength, validNumber } from '../utils/schema-utils'

interface IChangable {
  changed?: boolean
}

export enum Day {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Everyday = 'Everyday',
}

export enum ScheduleType {
  Always = 'Always',
  Periodic = 'Periodic',
  Range = 'Range',
  Custom = 'Custom',
}

export enum AggregationPoolType {
  SingleBook = 'SingleBook',
  MultiBook = 'MultiBook',
  ExceptClose = 'ExceptClose',
  NetExceptClose = 'NetExceptClose',
  MultiProportionalBook = 'MultiProportionalBook',
  ProportionalExceptClose = 'ProportionalExceptClose',
}

export enum PriceOptionTypes {
  WORST_PRICE = 'WORST_PRICE',
  FIRST_PRICE = 'FIRST_PRICE',
  ORDER_PRICE = 'ORDER_PRICE',
  BEST_PRICE = 'BEST_PRICE',
  LAST_PRICE = 'LAST_PRICE',
  VW_PRICE = 'VW_PRICE',
  VW_PRICE_STRICTVOL = 'VW_PRICE_STRICTVOL',
  RANDOM_PRICE = 'RANDOM_PRICE',
  WORST_PERCENTAGE_PRICE = 'WORST_PERCENTAGE_PRICE',
  WORST_MARKET_PRICE = 'WORST_MARKET_PRICE',
}

export enum OrderType {
  Market = 'Market',
  Limit = 'Limit',
  Stop = 'Stop',
  TP = 'TP',
  SL = 'SL',
  Stopout = 'Stopout',
  MarketOpen = 'MarketOpen',
  MarketClose = 'MarketClose',
  LimitOpen = 'LimitOpen',
  LimitClose = 'LimitClose',
  All = 'All',
}

export enum OrderSide {
  Buy = 'Buy',
  Sell = 'Sell',
  All = 'All',
}

export enum LpType {
  Bbook = 'Bbook',
  BbookHedged = 'BbookHedged',
  VolumeConverter = 'VolumeConverter',
}

export class Changable implements IChangable {
  public changed: boolean
  public highlighted: boolean
  public activeSwitches: boolean

  constructor(item?: Changable) {
    this.changed = (item && item.changed) || false
    this.highlighted = (item && item.highlighted) || false
    this.activeSwitches = (item && item.activeSwitches) || false
  }

  public setChanged() {
    this.changed = true
  }
}

export class ConnectionString {
  Server: string
  Port: number
  UserId: string
  Password: string
  Database: string
  flag: boolean;
  [key: string]: any

  static schema(type: string) {
    return {
      Server: yup.string().required(),
      Port: portSchema(),
      UserId: yup.string().required(),
      Password: passwordSchema(type),
      Database: yup.string().required(),
    }
  }

  static getDefault() {
    return {
      Server: 'localhost',
      Port: 5432,
      UserId: '',
      Password: '',
      Database: '',
      flag: false,
    }
  }

  constructor(item: any) {
    this.Server = item.Server || ''
    this.Port = item.Port || 5432
    this.UserId = item.UserId ? item.UserId : ''
    this.Password = item.Password ? item.Password : ''
    this.Database = item.Database ? item.Database : ''
    this.flag = false
  }
}

export class RmqSettings {
  RMQHost: string
  RMQPort: string
  RMQVirtualHost: string
  RMQLogin: string
  RMQPassword: string
  RMQExchange: string
  RMQQueue: string

  constructor(item: any) {
    this.RMQHost = item.RMQHost || 'localhost'
    this.RMQPort = item.RMQPort || '5672'
    this.RMQVirtualHost = item.RMQVirtualHost || '/'
    this.RMQLogin = item.RMQLogin || ''
    this.RMQPassword = item.RMQPassword || ''
    this.RMQExchange = item.iRMQExchange || 'rfb.direct'
    this.RMQQueue = item.RMQQueue || 'RFB'
  }
}

export class RoutingRuleEntity extends Changable {
  Id: number
  Name: string
  ProcessorId: any
  Logins: string
  Groups: string
  Symbols: string
  OrderTypes: IMultiSelectItem[]
  OrderSides: OrderSide
  Platforms: IMultiSelectItem[]
  Security: string
  MinVolume: any
  MaxVolume: any
  Schedule: Schedule
  IsAllowedColors: boolean
  Colors: IColor[]
  Enabled: boolean
  ProcessorName: string
  Profile: string
  isSelectedChecked: boolean
  flagImport?: boolean
  flag?: boolean
  Countries?: any

  static schema = {
    Name: yup.string().required(),
    ProcessorId: yup.string().required(),
    ProcessorName: yup.string().required(),
    Logins: loginsSchema(),
    Groups: yup.string().required(),
    Symbols: yup.string().required(),
    OrderTypes: yup.array().required(),
    OrderSides: yup.string().required(),
    Platforms: yup.array().required(),
    Security: yup.string().required(),
    MinVolume: yup
      .string()
      .matches(/^[0-9.]+$/gi)
      .transform(MaxLength)
      .required()
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0),
    MaxVolume: yup
      .string()
      .matches(/^[0-9.]+$/gi)
      .transform(MaxLength)
      .required()
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0),
    Colors: yup.array().required(),
    Profile: yup.string().required(),
    Countries: yup.array().required(),
  }

  constructor(item?: any) {
    super(item)
    if (item) {
      this.Countries = item.Countries ? buildMultiselectOptionsFromArray(item.Countries) : []
      this.flag = item.flag
      this.flagImport = item.flagImport
      this.isSelectedChecked = item.isSelectedChecked
      this.Profile = item.Profile
      this.Id = item.Id
      this.Name = item.Name
      this.ProcessorId = item.ProcessorId
      this.ProcessorName = item.ProcessorName ?? ''
      this.Logins = item.Logins
      this.Groups = item.Groups
      this.Symbols = item.Symbols
      this.OrderTypes = item.OrderTypes ? buildMultiselectOptionsFromArray(item.OrderTypes) : []
      this.OrderSides = item.OrderSides ?? OrderSide.All
      this.Platforms = item.Platforms ? buildMultiselectOptionsFromArray(item.Platforms) : []
      this.Security = item.Security ?? '*'
      this.MinVolume = item.MinVolume
      this.MaxVolume = item.MaxVolume
      this.Schedule = item.Schedule
      this.IsAllowedColors = item.IsAllowedColors ?? false
      this.Colors = item.Colors ?? []
      this.Enabled = item.Enabled
    } else {
      this.Countries = buildMultiselectOptionsFromEnum(['*'])
      this.flag = false
      this.flagImport = false
      this.isSelectedChecked = false
      this.Profile = ''
      this.ProcessorName = ''
      this.Id = 0
      this.Name = ''
      this.ProcessorId = ''
      this.Logins = '*'
      this.Groups = '*'
      this.Symbols = '*'
      this.OrderTypes = buildMultiselectOptionsFromEnum([OrderType.All])
      this.OrderSides = OrderSide.All
      this.Platforms = []
      this.Security = '*'
      this.MinVolume = 0
      this.MaxVolume = 1000
      this.Schedule = new Schedule()
      this.IsAllowedColors = false
      this.Colors = []
      this.Enabled = true
    }
  }
}

export function isProroptionalPool(item: AggregationPoolEntity) {
  return item.AggregationType === AggregationPoolType.MultiProportionalBook || item.AggregationType === AggregationPoolType.ProportionalExceptClose
}

export interface IExecutorsPercent {
  LPName: string
  Percent: number
}

export class AggregationPoolEntity extends Changable {
  public Name: string
  public Lps: IMultiSelectItem[]
  public Backup: string
  public BookType: string
  public AggregationType: AggregationPoolType
  public ExecutorsPercents: IExecutorsPercent[]
  public Enabled: boolean
  public Profile: string
  public isSelectedChecked: boolean
  public bunchProfiles: any
  public flagImport?: boolean
  public BWhenABookExecution?: boolean
  public AccumulateLpDeals?: boolean
  public HideAggregationTimeout?: string

  constructor(item?: any) {
    super(item)
    if (item) {
      this.HideAggregationTimeout = item.HideAggregationTimeout
      this.AccumulateLpDeals = item.AccumulateLpDeals
      this.BWhenABookExecution = item.BWhenABookExecution
      this.flagImport = item.flagImport
      this.bunchProfiles = item.bunchProfiles
      this.isSelectedChecked = item.isSelectedChecked
      this.Profile = item.Profile
      this.Name = item.Name
      this.Lps = item.Lps ? buildMultiselectOptionsFromArray(item.Lps) : []
      this.Backup = item?.Backup
      this.BookType = item.BookType
      this.AggregationType = item.AggregationType
      this.ExecutorsPercents = item.ExecutorsPercents
      this.Enabled = item.Enabled
    } else {
      this.HideAggregationTimeout = '10000'
      this.AccumulateLpDeals = false
      this.BWhenABookExecution = false
      this.flagImport = false
      this.bunchProfiles = []
      this.isSelectedChecked = false
      this.Profile = ''
      this.Name = ''
      this.Lps = []
      this.ExecutorsPercents = []
      this.AggregationType = AggregationPoolType.SingleBook
      this.Backup = ''
      this.BookType = ''
      this.Enabled = true
    }
  }
}

export class Schedule {
  ScheduleType: ScheduleType
  Days: IMultiSelectItem[]
  Start: number
  Duration: number
  Begin: number
  End: number

  static schema = {
    Days: yup.array().required(),
  }

  constructor(schedule?: any) {
    if (schedule) {
      this.ScheduleType = schedule.ScheduleType
      this.Days = schedule.Days ? buildMultiselectOptionsFromArray(schedule.Days) : []
      this.Start = schedule.Start
      this.Duration = schedule.Duration
      this.Begin = schedule.Begin
      this.End = schedule.End
    } else {
      this.ScheduleType = ScheduleType.Always
      this.Days = buildMultiselectOptionsFromArray([Day.Everyday])
      this.Start = 0
      this.Duration = 0
      this.Begin = 0
      this.End = 0
    }
  }
}

export class ExecutionLpAEntity extends Changable {
  Type: string
  Name: string
  Feeder: string
  AllowPartialFilling: boolean
  AllowLimitsOnLP: boolean
  Enabled: boolean
  bunchProfiles: any
  flagImport?: boolean

  constructor(item?: any) {
    super(item)
    if (item) {
      this.flagImport = item.flagImport
      this.bunchProfiles = item.bunchProfiles ?? [item.Profile]
      this.Type = item.Type
      this.Name = item.Name
      this.Feeder = item.Feeder
      this.AllowPartialFilling = item.AllowPartialFilling
      this.AllowLimitsOnLP = item.AllowLimitsOnLP
      this.Enabled = item.Enabled
    } else {
      this.flagImport = false
      this.bunchProfiles = []
      this.Type = 'All'
      this.Name = ''
      this.Feeder = ''
      this.AllowPartialFilling = true
      this.AllowLimitsOnLP = false
      this.Enabled = true
    }
  }
}

export class ExecutionLpBRule {
  Id: number
  Symbols: string
  Logins: string
  Groups: string
  MinVolume: any
  MaxVolume: any
  OrderTypes: IMultiSelectItem[]
  OrderSides: OrderSide

  DelayFrom: number
  DelayTo: number
  TicksThreshold: number
  ConsiderConnectorSpread: boolean
  PriceOption: string
  WorstPricePercentage: number
  Security: string
  Enabled: boolean;
  [key: string]: any

  static schema = {
    Symbols: yup.string().required(),
    Logins: loginsSchema(),
    Groups: yup.string().required(),
    MinVolume: yup
      .string()
      .matches(/^[0-9.-]+$/gi)
      .transform(MaxLength)
      .required()
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0),
    MaxVolume: yup
      .string()
      .matches(/^[0-9.-]+$/gi)
      .transform(MaxLength)
      .required()
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0),
    OrderTypes: yup.array().required(),
    OrderSides: yup.string().required(),

    DelayFrom: validNumber(true),
    DelayTo: validNumber(true),
    TicksThreshold: validNumber(true),
    ConsiderConnectorSpread: yup.boolean().required(),
    PriceOption: yup.string().required(),
    WorstPricePercentage: yup
      .string()
      .matches(/^[0-9]+$/gi)
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => Number(value) >= 0 && Number(value) <= 100)
      .notRequired(),
    Security: yup.string().required(),
  }

  constructor(item?: Partial<ExecutionLpBRule>) {
    if (typeof item === 'undefined') {
      item = Object.create(null)
    }
    this.Id = item?.Id ?? 0
    this.Symbols = item?.Symbols ?? '*'
    this.Logins = item?.Logins ?? '*'
    this.Groups = item?.Groups ?? '*'
    this.MinVolume = item?.MinVolume ?? 0
    this.MaxVolume = item?.MaxVolume ?? 0
    this.OrderTypes = buildMultiselectOptionsFromArray(item?.OrderTypes) ?? [{ label: OrderType.All, value: OrderType.All }]
    this.OrderSides = item?.OrderSides ?? OrderSide.All

    this.DelayFrom = item?.DelayFrom ?? 0
    this.DelayTo = item?.DelayTo ?? 0
    this.TicksThreshold = item?.TicksThreshold ?? 0
    this.ConsiderConnectorSpread = item?.ConsiderConnectorSpread ?? false
    this.PriceOption = item?.PriceOption ?? PriceOptionTypes.WORST_PRICE
    this.WorstPricePercentage = item?.WorstPricePercentage ?? 0
    this.Security = item?.Security ?? '*'
    this.Enabled = item?.Enabled ?? true
  }
}

export class ExecutionLpBEntity extends Changable {
  Type: string
  Name: string
  Rules: ExecutionLpBRule[]
  bunchProfiles: any
  flagImport?: boolean

  constructor(item?: any) {
    super(item)
    if (item) {
      this.flagImport = item.flagImport
      this.bunchProfiles = item.bunchProfiles ?? [item.Profile]
      this.Type = item.Type
      this.Name = item.Name
      this.Rules = item.Rules && item.Rules.map((item: any) => new ExecutionLpBRule(item))
    } else {
      this.flagImport = false
      this.bunchProfiles = []
      this.Type = 'All'
      this.Name = ''
      this.Rules = [new ExecutionLpBRule()]
    }
  }
}

export function isBbookLp(lp: ExecutionLpAEntity | ExecutionLpBEntity): boolean {
  return lp.Type === LpType.BbookHedged || lp.Type === LpType.Bbook || lp.Type === LpType.VolumeConverter
}

export class ProcessingRuleItem {
  Id: number
  Symbols: string
  Logins: string
  Groups: string
  MinVolume: any
  MaxVolume: any
  OrderTypes: IMultiSelectItem[]
  OrderSides: OrderSide
  MarkupFrom: number
  MarkupTo: number
  LimitMarkupFrom: number
  LimitMarkupTo: number
  RoundType: string
  ExecutionPriceSource: string
  DelayFrom: number
  DelayTo: number
  AllowContinuousExecutionForFOK: boolean
  Security: string
  Enabled: boolean;
  [key: string]: any

  static schema = {
    Symbols: yup.string().required(),
    Logins: loginsSchema(),
    Groups: yup.string().required(),
    MinVolume: yup
      .string()
      .matches(/^[0-9.-]+$/gi)
      .transform(MaxLength)
      .required()
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0),
    MaxVolume: yup
      .string()
      .matches(/^[0-9.-]+$/gi)
      .transform(MaxLength)
      .required()
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0),
    OrderTypes: yup.array().required(),
    OrderSides: yup.string().required(),
    MarkupFrom: yup.string().matches(/^[0-9-]+$/gi),
    MarkupTo: yup.string().matches(/^[0-9-]+$/gi),
    LimitMarkupFrom: yup.string().matches(/^[0-9-]+$/gi),
    LimitMarkupTo: yup.string().matches(/^[0-9-]+$/gi),
    RoundType: yup.string().required(),
    ExecutionPriceSource: yup.string().required(),
    DelayFrom: validNumber(true),
    DelayTo: validNumber(true),
    AllowContinuousExecutionForFOK: yup.boolean().required(),
    Security: yup.string().required(),
  }

  constructor(item?: any) {
    if (item) {
      this.Id = item.Id
      this.Symbols = item.Symbols
      this.Logins = item.Logins
      this.Groups = item.Groups
      this.MinVolume = item.MinVolume
      this.MaxVolume = item.MaxVolume
      this.OrderTypes = buildMultiselectOptionsFromArray(item.OrderTypes)
      this.OrderSides = item.OrderSides ?? OrderSide.All

      this.MarkupTo = item.MarkupTo || 0
      this.MarkupFrom = item.MarkupFrom || 0
      this.LimitMarkupTo = item.LimitMarkupTo || 0
      this.LimitMarkupFrom = item.LimitMarkupFrom || 0
      this.DelayFrom = item.DelayFrom || 0
      this.DelayTo = item.DelayTo || 0
      this.RoundType = item.RoundType || 'None'
      this.ExecutionPriceSource = item.ExecutionPriceSource
      this.AllowContinuousExecutionForFOK = item.AllowContinuousExecutionForFOK
      this.Security = item.Security ?? '*'
      this.Enabled = item.Enabled
    } else {
      this.Id = 0
      this.Symbols = '*'
      this.Logins = '*'
      this.Groups = '*'
      this.MinVolume = 0
      this.MaxVolume = 0
      this.OrderTypes = [{ label: OrderType.All, value: OrderType.All }]
      this.OrderSides = OrderSide.All

      this.MarkupTo = 0
      this.MarkupFrom = 0
      this.LimitMarkupTo = 0
      this.LimitMarkupFrom = 0
      this.RoundType = 'None'
      this.ExecutionPriceSource = 'LP'
      this.DelayFrom = 0
      this.DelayTo = 0
      this.AllowContinuousExecutionForFOK = false
      this.Security = '*'
      this.Enabled = true
    }
  }
}

export class ProcessingRuleItemNotValid {
  Id: number
  Symbols: string
  Logins: string
  Groups: string
  MinVolume: any
  MaxVolume: any
  OrderTypes: IMultiSelectItem[]
  OrderSides: OrderSide
  MarkupFrom: number
  MarkupTo: number
  LimitMarkupFrom: number
  LimitMarkupTo: number
  RoundType: string
  ExecutionPriceSource: string
  DelayFrom: number
  DelayTo: number
  AllowContinuousExecutionForFOK: boolean
  Security: string
  Enabled: boolean;
  [key: string]: any

  static schema = {
    Symbols: yup.string().required(),
    Logins: loginsSchema(),
    Groups: yup.string().required(),
    MinVolume: yup
      .string()
      .matches(/^[0-9.-]+$/gi)
      .transform(transformEmptyStringMaxLength)
      .required()
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0),
    OrderTypes: yup.array().required(),
    OrderSides: yup.string().required(),
    MarkupFrom: yup.string().matches(/^[0-9-]+$/gi),
    MarkupTo: yup.string().matches(/^[0-9-]+$/gi),
    LimitMarkupFrom: yup.string().matches(/^[0-9-]+$/gi),
    LimitMarkupTo: yup.string().matches(/^[0-9-]+$/gi),
    RoundType: yup.string().required(),
    ExecutionPriceSource: yup.string().required(),
    DelayFrom: validNumber(true),
    DelayTo: validNumber(true),
    AllowContinuousExecutionForFOK: yup.boolean().required(),
    Security: yup.string().required(),
  }

  constructor(item?: any) {
    if (item) {
      this.Id = item.Id
      this.Symbols = item.Symbols
      this.Logins = item.Logins
      this.Groups = item.Groups
      this.MinVolume = item.MinVolume
      this.MaxVolume = item.MaxVolume
      this.OrderTypes = buildMultiselectOptionsFromArray(item.OrderTypes)
      this.OrderSides = item.OrderSides ?? OrderSide.All

      this.MarkupTo = item.MarkupTo || 0
      this.MarkupFrom = item.MarkupFrom || 0
      this.LimitMarkupTo = item.LimitMarkupTo || 0
      this.LimitMarkupFrom = item.LimitMarkupFrom || 0
      this.DelayFrom = item.DelayFrom || 0
      this.DelayTo = item.DelayTo || 0
      this.RoundType = item.RoundType || 'None'
      this.ExecutionPriceSource = item.ExecutionPriceSource
      this.AllowContinuousExecutionForFOK = item.AllowContinuousExecutionForFOK
      this.Security = item.Security ?? '*'
      this.Enabled = item.Enabled
    } else {
      this.Id = 0
      this.Symbols = '*'
      this.Logins = '*'
      this.Groups = '*'
      this.MinVolume = 0
      this.MaxVolume = 0
      this.OrderTypes = [{ label: OrderType.All, value: OrderType.All }]
      this.OrderSides = OrderSide.All

      this.MarkupTo = 0
      this.MarkupFrom = 0
      this.LimitMarkupTo = 0
      this.LimitMarkupFrom = 0
      this.RoundType = 'None'
      this.ExecutionPriceSource = 'LP'
      this.DelayFrom = 0
      this.DelayTo = 0
      this.AllowContinuousExecutionForFOK = false
      this.Security = '*'
      this.Enabled = true
    }
  }
}

export class ProcessingRuleEntity extends Changable {
  public Name: string
  public Id: any
  public LpPool: string
  public Rules: ProcessingRuleItem[]
  public Profile: string
  public isSelectedChecked: boolean
  public flagImport?: boolean

  static schema = {
    Name: folderNameSchema(),
    LpPool: yup.string().required(),
    Profile: yup.string().required(),
  }

  constructor(item?: any) {
    super(item)
    if (item) {
      this.flagImport = item.flagImport
      this.isSelectedChecked = item.isSelectedChecked
      this.Profile = item.Profile
      this.Name = item.Name || ''
      this.Id = item.Id || ''
      this.LpPool = item.LpPool || ''
      this.Rules = (item.Rules && item.Rules.map((rule: any) => new ProcessingRuleItem({ ...rule, Id: -Math.floor(Math.random() * (2000000000 + 1 - 1)) }))) || []
    } else {
      this.flagImport = false
      this.isSelectedChecked = false
      this.Profile = ''
      this.Name = ''
      this.Id = ''
      this.LpPool = ''
      this.Rules = [new ProcessingRuleItem()]
    }
  }
}

export const scheduleTypes = ['Always', 'Periodic', 'Range']

export const roundTypes = ['None', 'Arithmetic', 'TraderWorst']

export const commissionTypes = ['None', 'PercentFromVolume', 'PercentFromVolumeOnPrice', 'PercentFromVolumeOnPriceWithContractSize', 'PerMillionUsd']
