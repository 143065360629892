import { Dispatch } from 'redux'
import { LiquidityProvider } from '../../entity/feeding'
import { throwSuccessMessage } from '../../utils/errors-utils'
import { buildHTTPGetOptions, buildHTTPPostOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { optionsToStrings } from '../../utils/multiselect-utils'
import { Action } from './actions'
import { FeedExecPresetEntity } from '../../entity/Preset-entitu'

export function feedingConfigurationChanged() {
  return { type: Action.FeedingConfigurationChanged }
}

export function feedingConfigurationInitial() {
  return { type: Action.FeedingConfigurationInitial }
}

export function addFeedingLp(lp: LiquidityProvider) {
  lp.changed = true
  return { type: Action.AddFeedingLp, data: lp }
}

export function addFeedingLpImport(lp: LiquidityProvider) {
  lp.flagImport = true
  return { type: Action.AddFeedingLp, data: lp }
}

export function deleteFeedPreset() {
  return { type: Action.DeleteFeedPreset }
}

export function deleteFeedPresetOne(data: any) {
  return { type: Action.DeleteFeedPresetOne, data }
}

export function changeFeedingLp(lp: any) {
  if (lp.flag) {
    lp.changed = false
  } else {
    lp.changed = true
  }

  return { type: Action.ChangeFeedingLp, data: lp }
}

export function changeFeedingLpNoChanged(lp: any) {
  return { type: Action.ChangeFeedingLp, data: lp }
}

export function changeFeedingLpImport(lp: any) {
  lp.flagImport = true
  return { type: Action.ChangeFeedingLp, data: lp }
}

export function changeFeedingPreset(lp: any) {
  lp.changed = true
  return { type: Action.ChangeFeedingPreset, data: lp }
}
export function deleteFeedingLp(lp: LiquidityProvider) {
  return { type: Action.DeleteFeedingLp, data: lp }
}

export function highlightRelevantFeeding(item: any) {
  return { type: Action.FeedingHighlightRelevant, data: item }
}

export const feedingSelector = (state: any) => state.feedingConfiguration

export function onlyFetchFeeding(params: any) {
  const url = new URL('/api/feeding', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
}

export function fetchFeeding(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return onlyFetchFeeding(params)
      .then((data: any) => {
        const feedAgg = data.FeedAggregations.map((item: any) => {
          const arr: any = []
          data.FeedPlatforms.forEach((el: any) => {
            if (el.AggregatedFeeder === item.Id) {
              arr.push(el.Profile)
            }
          })
          return {
            ...item,
            bunchProfiles: arr.filter((item: any, i: any, arr: any) => arr.indexOf(item) === i),
          }
        })

        const lpNames = data.Lps.map((item: any) => {
          let arr: any = []
          feedAgg.forEach((el: any) => {
            if (el.Feeders.includes(item.Id)) {
              arr.push(...el.bunchProfiles)
            }
          })
          return {
            ...item,
            bunchProfiles: arr.flat(Infinity),
          }
        })

        dispatch({ type: Action.GotLP, data: lpNames })
        dispatch({
          type: Action.GotFeedAgregation,
          data: feedAgg,
        })
        dispatch({ type: Action.GotFeedPlatforms, data: data.FeedPlatforms })

        dispatch({ type: Action.GotFeedPlatformsOld, data: data })

        dispatch({ type: Action.GotPreset, data: data.Profiles })

        dispatch(feedingConfigurationInitial())
      })
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

//==========
export function onlyFetchFeedingPool(params: any) {
  const url = new URL('api/feeding/GetSyntheticLpAggregations', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
}

export function fetchFeedingPool(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return onlyFetchFeedingPool(params)
      .then((data: any) => {
        dispatch({ type: Action.GotFeedPool, data: data })
      })
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}
//==========

export function fetchFeedingUpdate(arg: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/feeding/update', window.location.origin)
    url.searchParams.set('gateway', arg.params.Gateway)

    const req: any = {}
    const changableObj = {
      changed: undefined,
      highlighted: undefined,
    }
    const table: any = {}
    const tablePreset = arg.body.Profile.filter((item: FeedExecPresetEntity) => !table[item.Name] && (table[item.Name] = 1))

    req.FeedPlatforms = arg.body.feedPlatforms.map((item: any) => {
      return {
        ...item,
        newPlatformFlag: false,
        Rules: item.Rules.map((rule: any) => ({
          ...rule,
          Symbols: optionsToStrings(rule.Symbols),
        })),
        ...changableObj,
      }
    })
    req.Lps = arg.body.lp.map((item: any) => ({ ...item, ...changableObj }))
    req.Profiles = tablePreset.map((item: any) => {
      return {
        ...item,
        Days: optionsToStrings(item.Days),
      }
    })
    req.FeedAggregations = arg.body.feedAgregations.map((item: any) => ({
      ...item,
      ...changableObj,
    }))

    dispatch({ type: Action.InProgressStart })
    arg.params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions(req))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(`feeding.status.${result.Status}`)))
        }
        return onlyFetchFeeding(arg.params)
      })
      .then((data: any) => {
        const feedAgg = data.FeedAggregations.map((item: any) => {
          const arr: any = []
          data.FeedPlatforms.forEach((el: any) => {
            if (el.AggregatedFeeder === item.Id) {
              arr.push(el.Profile)
            }
          })
          return {
            ...item,
            bunchProfiles: arr.filter((item: any, i: any, arr: any) => arr.indexOf(item) === i),
          }
        })

        const lpNames = data.Lps.map((item: any) => {
          let arr: any = []
          feedAgg.forEach((el: any) => {
            if (el.Feeders.includes(item.Id)) {
              arr.push(...el.bunchProfiles)
            }
          })
          return {
            ...item,
            bunchProfiles: arr.flat(Infinity),
          }
        })

        dispatch({ type: Action.GotLP, data: lpNames })
        dispatch({
          type: Action.GotFeedAgregation,
          data: feedAgg,
        })
        dispatch({ type: Action.GotFeedPlatforms, data: data.FeedPlatforms })
        dispatch({ type: Action.GotPreset, data: data.Profiles })
        dispatch(feedingConfigurationInitial())
        throwSuccessMessage('Successfully')
      })
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        arg.params.setLoading(false)
      })
  }
}

export function addFeedPlatformSymbol(data: any) {
  return { type: Action.AddFeedPlatformSymbol, data }
}

export function replaceFeedPlatformSymbol(data: any) {
  return { type: Action.replaceFeedPlatformSymbol, data }
}

export function deleteFeedPlatformSymbol(data: any) {
  return { type: Action.DeleteFeedPlatformSymbol, data }
}

export function deleteFeedPlatformSymbols(data: any) {
  return { type: Action.DeleteFeedPlatformSymbols, data }
}

export function changeFeedPlatformSymbol(data: any) {
  return { type: Action.ChangeFeedPlatformSymbol, data }
}

export function getFeedPlatformSymbols(data: any) {
  return { type: Action.GotFeedPlatformSymbols, data }
}
