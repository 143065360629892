import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { fetchLpSymbolMapUpdate, onlyFetchGlobalSymbols } from '../../redux/actions/system-settings-actions'
import { buildControlsExtTwoPerLine, sselectInput, textInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'
import { MaxLength } from '../../utils/schema-utils'

const schema = {
  Symbol: yup
    .object({
      value: yup
        .string()
        .test('Is Unknown?', 'ERROR: Symbol Unknown!', value => value !== 'Unknown')
        .required(),
      label: yup
        .string()
        .test('Is Unknown?', 'ERROR: Symbol Unknown!', value => value !== 'Unknown')
        .required(),
    })
    .required(),
  LpSymbol: yup
    .string()
    .matches(/^[^=]+$/gi)
    .required(),
  ContractMultiplier: yup
    .string()
    .matches(/^[0-9.]+$/gi)
    .transform(MaxLength)
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
  VolumeStep: yup
    .string()
    .matches(/|^[0-9.]+$/gi)
    .transform(MaxLength)
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0 || !value)
    .nullable()
    .notRequired(),
}

const LpSymbolMapRightbar: React.FC<IRightbar> = ({ data: { type, item, params } }) => {
  const dispatch = useDispatch()

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      ContractSize: String(item.ContractSize) ?? '',
      Symbol: { label: item.Symbol, value: item.Symbol },
    },
    schema,
  )

  const [globalSymbols, setGlobalSymbols] = useState<string[]>([])
  const handleSave = () => {
    if (!isValid()) {
      return
    }

    if (!globalSymbols.includes(item.Symbol) && type === 'modify') {
      new Promise(resolve => {
        resolve(dispatch(fetchLpSymbolMapUpdate({ action: 'delete', params, body: { ...item, Name: inputState.Name } })))
      }).then(e => {
        dispatch(
          fetchLpSymbolMapUpdate({
            action: 'add',
            params,
            body: { ...inputState, Symbol: inputState.Symbol.value, Name: inputState.Name },
          }),
        )
      })
    } else {
      dispatch(
        fetchLpSymbolMapUpdate({
          action: type,
          params,
          body: { ...inputState, Symbol: inputState.Symbol.value, Name: inputState.Name },
        }),
      )
    }

    dispatch(hideRightBar())
  }

  useEffect(() => {
    onlyFetchGlobalSymbols(params).then((symbols: any) => {
      setGlobalSymbols(symbols)
      if (type === 'add') {
        setInputState({ ...item, Symbol: { label: symbols, value: symbols } && { label: symbols[0], value: symbols[0] }, VolumeStep: null })
      }
      const newSymbils = symbols.map((item: any) => item.trim())
      if (!newSymbils?.includes(item.Symbol?.trim()) && type === 'modify') {
        setInputState({
          ...item,
          Symbol: { label: 'Unknown', value: 'Unknown' },
        })
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const newGlobalSymbols = globalSymbols.map((item: any) => {
    return {
      label: item,
      value: item,
    }
  })

  useEffect(() => {
    setInputState((prev: any) => {
      return {
        ...prev,
        VolumeStep: inputState.VolumeStep === '' ? null : inputState.VolumeStep,
      }
    })
  }, [inputState.VolumeStep]) // eslint-disable-line react-hooks/exhaustive-deps

  const inputs = buildControlsExtTwoPerLine(
    [
      sselectInput('Symbol', [...newGlobalSymbols]),
      textInput('LpSymbol', type === 'modify'),
      textInput('ContractMultiplier'),
      textInput('VolumeStep'),
      textInput('DealCurrency').skipWhen(params.LpType !== 'ADSSo' && params.LpType !== 'IG' && params.LpType !== 'IGDMA'),
      textInput('Type').skipWhen(params.LpType !== 'AdvancedMarkets'),
    ],
    inputState,
    setInputState,
    'lp-symbol-map',
    touched,
    setTouched,
    errors,
  )

  const ref = useRef(null)

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`symbol-map.${type}`} />,
          item: inputs,
        }}
        isHidden={false}
        ref={ref}
        render={ref.current}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
}
export default LpSymbolMapRightbar
