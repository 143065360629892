import React, { FC, useEffect, useMemo, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { buildControlsExtTwoPerLine, sselectInput, textInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import { IMultiSelectItem, MultiselectItem } from '../../entity/multiselect'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { useDispatch, useSelector } from 'react-redux'
import useData from '../../hooks/useData'
import useGateway from '../../hooks/useGateway'
import { RootState } from '../../redux/reducers/rootReducer'
import { fetchVolumeTransformerSymbolMap, fetchVolumeTransformerSymbolMapAdd, fetchVolumeTransformerSymbolMapModify } from '../../redux/actions/system-settings-actions'
import { transformGateway } from '../../utils/fetch-utils'

type props = {
  data: any
}
function asMultiselectOption(option: string): IMultiSelectItem {
  return new MultiselectItem(option)
}

const VolumeTransformerSymbolMapRightBar: FC<props> = ({ data: { item, type, pagination } }) => {
  const dispatch = useDispatch()
  const gateway = useGateway()
  const { VolumeTransformerSymbolMap } = useSelector((state: RootState) => state.sysLps)

  const [, setLoading] = useState(true)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      SourceSymbol: asMultiselectOption(item.SourceSymbol),
      TargetSymbol: asMultiselectOption(item.TargetSymbol),
      Multiplier: item.Multiplier ? item.Multiplier : '',
    },
    {
      Multiplier: yup.number().positive().required(),
      TargetSymbol: yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      }),
    },
  )

  const storeSymbol = VolumeTransformerSymbolMap.map((item: any) => item.SourceSymbol)
  const symbols = useData('api/hedgedBbook/symbols', [], gateway)
  const symbolOptions = useMemo(() => symbols.map(asMultiselectOption).filter((item: any) => !storeSymbol.includes(item.value)), [symbols]) // eslint-disable-line react-hooks/exhaustive-deps
  const symbolsTarget = useData('api/VolumeConverter/TargetSymbols', [], gateway, { lpName: window.location.search.slice(4) })
  const symbolOptionsTarget = useMemo(() => symbolsTarget.map(asMultiselectOption), [symbolsTarget])
  const sourceSymbol = VolumeTransformerSymbolMap.map((item: any) => item.SourceSymbol)
  let newSymbol = symbolOptions.filter((item: any) => item.value !== inputState.TargetSymbol.value).filter((item: any) => !sourceSymbol.includes(item))

  useEffect(() => {
    if (symbolOptions.length && (type === 'add' || type === 'clone')) {
      setInputState((prevState: any) => ({
        ...prevState,
        SourceSymbol: symbolOptions.filter((item: any) => item?.value !== symbolOptionsTarget[0]?.value)[0],
      }))
    }
    if (symbolOptionsTarget.length && type === 'add') {
      setInputState((prevState: any) => ({
        ...prevState,
        TargetSymbol: symbolOptionsTarget[0],
      }))
    }
  }, [symbolOptions, type, symbolOptionsTarget]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inputState?.TargetSymbol?.value === inputState?.SourceSymbol?.value) {
      setInputState((prevState: any) => ({
        ...prevState,
        SourceSymbol: symbolOptions.filter((item: any) => item.value !== inputState?.TargetSymbol?.value)[0],
      }))
    }
  }, [inputState?.TargetSymbol?.value]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = async () => {
    if (!isValid()) {
      return
    }
    const newItem = {
      ...inputState,
      SourceSymbol: inputState.SourceSymbol.value,
      TargetSymbol: inputState.TargetSymbol.value,
    }
    if (type === 'add') {
      await dispatch(fetchVolumeTransformerSymbolMapAdd(setLoading, newItem, transformGateway(gateway), { lpName: window.location.search.slice(4) }))
    }
    if (type === 'clone') {
      await dispatch(fetchVolumeTransformerSymbolMapAdd(setLoading, newItem, transformGateway(gateway), { lpName: window.location.search.slice(4) }))
    }
    if (type === 'modify') {
      await dispatch(
        fetchVolumeTransformerSymbolMapModify(setLoading, { previousSymbolName: item.SourceSymbol, symbolMapModel: { ...newItem } }, transformGateway(gateway), {
          lpName: window.location.search.slice(4),
        }),
      )
    }
    dispatch(hideRightBar())
    dispatch(fetchVolumeTransformerSymbolMap(setLoading, transformGateway(gateway), { lpName: window.location.search.slice(4) }, { Page: pagination.Page, Count: pagination.Count }))
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`volume.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine([sselectInput('SourceSymbol', newSymbol), textInput('Multiplier'), sselectInput('TargetSymbol', symbolOptionsTarget)], inputState, setInputState, 'VolumeTransformerSymbolMapRightBar', touched, setTouched, errors)}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleClick}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
}

export default VolumeTransformerSymbolMapRightBar
