import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { FeedAgregationEntity, FeedPlatformEntity } from '../../entity/feeding'
import { addFeedPlatform, changeFeedAggregationPlat, changeFeedPlatform } from '../../redux/actions/execution/execution-actions'
import { changeFeedingLp, feedingConfigurationChanged } from '../../redux/actions/feeding-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useFormValidation } from '../../hooks/useFormValidation'
import { buildControlsExtTwoPerLine, selectInput, textInput } from '../../utils/controls'
import * as yup from 'yup'
import { useTranslate } from '../../hooks/useTranslate'
import { folderNameSchema } from '../../utils/schema-utils'
import { FeedExecPresetEntity } from '../../entity/Preset-entitu'

interface IFeedAgregationRightbar extends IGatewayProp {
  data: any
}

const schema = {
  Name: folderNameSchema(),
  Connector: yup.string().required(),
  AggregatedFeeder: yup.number().required(),
  BackupFeedAggregationId: yup.number().required(),
  Profile: yup.string().required(),
}

const FeedPlatformRightbar: React.FC<IFeedAgregationRightbar> = ({ data: { type, item, preset }, gateway }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new FeedPlatformEntity(item), schema)
  const dispatch = useDispatch()
  const { feedAgregations, Profile, lp, feedPlatforms } = useSelector((state: any) => state.feedingConfiguration)
  const translate = useTranslate()

  const Filtertable: any = {}
  const FiltertablePreset = Profile.filter((el: FeedExecPresetEntity) => !Filtertable[el.Name] && (Filtertable[el.Name] = 1)).map((item: any) => item.Name)

  useEffect(() => {
    const connector = gateway.Platforms.filter((item: any) => item.Type !== 'mtexec')
    if (feedAgregations && type === 'add') {
      setInputState(
        new FeedPlatformEntity({
          ...inputState,
          Connector: connector[0]?.Name ?? '',
          AggregatedFeeder: feedAgregations[0]?.Id ?? '',
          Profile: preset === 'All' ? FiltertablePreset[0] : preset,
        }),
      )
    }
  }, [feedAgregations]) // eslint-disable-line react-hooks/exhaustive-deps

  const alreadyExist = () => {
    if (type === 'modify' && feedPlatforms.find((platform: any) => platform.Name === inputState.Name && platform.Profile === inputState.Profile && platform.Id !== inputState.Id)) {
      errors.Name = true
      return 'platform.exists'
    }
    if ((type === 'add' || type === 'clone') && feedPlatforms.find((platform: any) => platform.Name === inputState.Name && platform.Profile === inputState.Profile)) {
      errors.Name = true
      return 'platform.exists'
    }
    return ''
  }

  const [msg, setMsg] = useState({
    Profile: '',
    AggregatedFeeder: '',
  })

  const handleSave = async () => {
    if (!isValid() || !FiltertablePreset.length || !feedAgregations.length) {
      errors.Profile = true
      touched.Profile = true
      errors.AggregatedFeeder = true
      touched.AggregatedFeeder = true

      setMsg((prev: any) => {
        if (!FiltertablePreset.length && !feedAgregations.length) {
          return {
            Profile: 'You need to create a profile to proceed',
            AggregatedFeeder: 'You need to create a feeding pool to proceed',
          }
        } else if (!feedAgregations.length) {
          return {
            ...prev,
            AggregatedFeeder: 'You need to create a feeding pool to proceed',
          }
        } else if (!FiltertablePreset.length) {
          return {
            ...prev,
            Profile: 'You need to create a profile to proceed',
          }
        }
      })

      return
    }
    const findProfile = Profile.filter((item: any) => item.Name === inputState.Profile)
    const data = new FeedPlatformEntity({ ...inputState, Enabled: findProfile[0].Enabled })

    if (type === 'add' || type === 'clone') {
      feedAgregations?.forEach((item: any) => {
        if (Number(data?.AggregatedFeeder) === Number(item.Id)) {
          const nameLp = item.Feeders
          dispatch(changeFeedAggregationPlat({ ...item, bunchProfiles: [...item.bunchProfiles, data.Profile], Profile: data.Profile }))
          lp?.forEach((item: any) => {
            if (nameLp.includes(item.Id)) {
              dispatch(changeFeedingLp({ ...item, bunchProfiles: [...item.bunchProfiles, data.Profile], flag: true }))
            }
          })
        }
      })
      dispatch(addFeedPlatform(data))
    } else {
      feedAgregations?.forEach((item: any) => {
        if (Number(data?.AggregatedFeeder) === Number(item.Id)) {
          const nameLp = item.Feeders
          dispatch(changeFeedAggregationPlat({ ...item, bunchProfiles: [...item.bunchProfiles, data.Profile], Profile: data.Profile }))
          lp?.forEach((item: any) => {
            if (nameLp.includes(item.Id)) {
              dispatch(changeFeedingLp({ ...item, bunchProfiles: [...item.bunchProfiles, data.Profile], flag: true }))
            }
          })
        }
      })
      dispatch(changeFeedPlatform(data))
    }
    dispatch(feedingConfigurationChanged())
    dispatch(hideRightBar())
  }

  const convertFeederOptions = (option: number) => {
    if (option === 0) {
      return translate('None')
    }
    const aggregatedFeeder = feedAgregations.find((aggregation: FeedAgregationEntity) => aggregation.Id === option)
    if (aggregatedFeeder) {
      return aggregatedFeeder.Name
    }
    return option
  }

  const notCover: string[] = []
  gateway.Platforms.forEach((platform: any) => {
    if (platform.Type !== 'mtexec') {
      notCover.push(platform.Name)
    }
  })

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`feed-platform.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('Name').errorMessage(alreadyExist()),
            selectInput('Connector', notCover || []),
            selectInput(
              'AggregatedFeeder',
              feedAgregations.map((aggregation: FeedAgregationEntity) => aggregation.Id),
              !item.Enabled && type !== 'clone',
              inputState,
              setInputState,
              convertFeederOptions,
            ).errorMessage(msg?.AggregatedFeeder),
            selectInput('BackupFeedAggregationId', [0, ...feedAgregations.map((aggregation: FeedAgregationEntity) => aggregation.Id)], !item.Enabled && type !== 'clone', inputState, setInputState, convertFeederOptions),
            selectInput('Profile', FiltertablePreset).errorMessage(msg?.Profile),
          ],
          inputState,
          setInputState,
          'feed-platforms',
          touched,
          setTouched,
          errors,
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default withGateway(FeedPlatformRightbar)
