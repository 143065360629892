import { AppAccordion } from '@t4b/core/lib'
import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchPlatformFixSymbolsUpdate } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { blankInput, buildControlsExtTwoPerLine, checkboxInput, selectInput, textInput } from '../../utils/controls'
import { IRightbar } from './rightbar-types'
import { alreadyExistFixExtSymbols, alreadyTest } from './lpRightbarUtils/utils'
import { transformEmptyStringMaxLength } from '../../utils/schema-utils'
import { expToNum } from '../../utils/num-utils'

const schema = {
  PlatformSymbol: yup.string().required(),
  ContractMultiplier: yup
    .number()
    .required()
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
  ContractSize: yup
    .number()
    .required()
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
  Type: yup.string().required(),
  BaseCurrency: yup
    .string()
    .transform((_, val) => (val === String(val) ? val : null))
    .required(),
  QuoteCurrency: yup.string().required(),
  CanBeUsedForConvert: yup.boolean(),
  Digits: yup
    .number()
    .integer()
    .required()
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0),
  VolumeStep: yup
    .number()
    .required()
    .required()
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0),
  StrongHedgedMargin: yup.boolean().required(),
  InvalidateTimeout: yup
    .string()
    .matches(/^[0-9]+$/gi)
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0)
    .required(),
  Commission: yup.number().required(),
  SwapLong: yup
    .string()
    .matches(/^-?[0-9.]+$/gi)
    .transform(transformEmptyStringMaxLength)
    .required(),
  SwapShort: yup
    .string()
    .matches(/^-?[0-9.]+$/gi)
    .transform(transformEmptyStringMaxLength)
    .required(),
  DisableFOK: yup.boolean().required(),
}

const FixSymbolsRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const { fixSymbolMap } = useSelector((state: any) => state.fixConfiguration)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      SwapLong: item.SwapLong ? expToNum(item.SwapLong) : String(item.SwapLong),
      SwapShort: item.SwapShort ? expToNum(item.SwapShort) : String(item.SwapShort),
    },
    schema,
  )

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    dispatch(
      fetchPlatformFixSymbolsUpdate({
        action: type,
        params: { ...params },
        body: inputState.Type !== 'CFD' ? inputState : { ...inputState, BaseCurrency: null, CanBeUsedForConvert: false },
      }),
    )
    dispatch(hideRightBar())
  }

  useEffect(() => {
    setInputState((prev: any) => {
      return {
        ...prev,
        BaseCurrency: prev.BaseCurrency === 'N/A' ? '' : prev.BaseCurrency,
        CanBeUsedForConvert: prev.CanBeUsedForConvert !== false,
      }
    })
  }, [])

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('PlatformSymbol')
        .disabled(type === 'modify')
        .errorMessage(alreadyExistFixExtSymbols(type, [...fixSymbolMap.data], inputState, errors)),
      textInput('ContractMultiplier'),
      textInput('ContractSize'),
      selectInput('Type', ['Forex', 'CFD']),
      textInput('BaseCurrency')
        .skipWhen(inputState.Type === 'CFD')
        .errorMessage(alreadyTest(inputState, errors)),
      textInput('QuoteCurrency'),
      blankInput(),
      checkboxInput('CanBeUsedForConvert').skipWhen(inputState.Type === 'CFD'),
      textInput('Digits'),
      textInput('VolumeStep'),
      checkboxInput('StrongHedgedMargin', true),
      checkboxInput('DisableFOK', true),
      selectInput('CommissionType', ['Volume per $M', 'Fixed per deal', 'Fixed per order', 'Fixed per lot']),
      textInput('Commission'),
      textInput('SwapLong'),
      textInput('SwapShort'),
      textInput('InvalidateTimeout'),
    ],
    inputState,
    setInputState,
    'fix-symbols',
    touched,
    setTouched,
    errors,
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`symbol-map.title`} />,
          item: inputs,
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
})

export default FixSymbolsRightbar
