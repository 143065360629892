import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { AggregationPoolEntity, ProcessingRuleEntity, ProcessingRuleItem, ProcessingRuleItemNotValid, roundTypes } from '../../entity/configuration'
import { useFormValidation } from '../../hooks/useFormValidation'
import { useTranslate } from '../../hooks/useTranslate'
import { addProcessingRule, changeAggregationPool, changeExecutionLpOnePreset, changeProcessingRule, changeRoutingRuleNoChange, executionConfigurationChanged } from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import { buildControlsExtTwoPerLine, checkboxInput, executionPriceSourceConverter, roundTypesConverter, selectInput, textInput } from '../../utils/controls'
import { IRightbar } from './rightbar-types'

const ProcessingRuleRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, preset } }) => {
  const starRule = item.Rules[item.Rules.length - 1] || new ProcessingRuleItem()
  const [common, setCommon, commonTouched, setCommonTouched, commonErrors, isCommonValid] = useFormValidation(new ProcessingRuleEntity(item), ProcessingRuleEntity.schema)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new ProcessingRuleItem({ ...starRule, MinVolume: String(starRule.MinVolume), MaxVolume: String(starRule.MaxVolume) }), ProcessingRuleItemNotValid.schema)

  const dispatch = useDispatch()
  const { aggregationPools, Profile, lps, processingRules, routingRules } = useSelector((state: RootState) => state.executionConfiguration)
  const { gateway } = useSelector((state: RootState) => state.gateways)
  const translate = useTranslate()
  const presetName = Profile.map((item: any) => item.Name)

  useEffect(() => {
    if (!common.Profile && type === 'add') {
      setCommon((prev: any) => {
        return {
          ...prev,
          Profile: preset === 'All' ? presetName[0] : preset,
        }
      })
    }
  }, [Profile]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((common.Profile && type === 'add') || type === 'add') {
      setCommon((prev: any) => {
        return {
          ...prev,
          LpPool: aggregationPools[0]?.Name ?? '',
        }
      })
    }
  }, [common.Profile]) // eslint-disable-line react-hooks/exhaustive-deps

  const alreadyExist = () => {
    if (type === 'modify' && processingRules.find((platform: any) => platform.Name === common.Name && platform.Profile === common.Profile && platform.Id !== common.Id)) {
      commonErrors.Name = true
      return 'processing.exists'
    }
    if ((type === 'add' || type === 'clone') && processingRules.find((platform: any) => platform.Name === common.Name && platform.Profile === common.Profile)) {
      commonErrors.Name = true
      return 'processing.exists'
    }
    return ''
  }

  const [msg, setMsg] = useState('')

  const handleSave = () => {
    if (!isValid() || !isCommonValid() || !presetName.length) {
      if (!common.Name?.lenght) {
        commonErrors.Name = true
        commonTouched.Name = true
      }

      commonErrors.Profile = true
      commonTouched.Profile = true
      setMsg('You need to create a profile to proceed')
      return
    }

    const findProfile = Profile.filter((item: any) => item.Name === common.Profile)
    const newRule = new ProcessingRuleEntity({
      ...item,
      ...common,
      Name: common.Name.trim(),
      Rules: [...item.Rules.slice(0, item.Rules.length - 1), new ProcessingRuleItem(inputState)].map((item: any) => {
        return {
          ...item,
          Enabled: findProfile[0].Enabled,
          Id: -Math.floor(Math.random() * (2000000000 + 1 - 1)),
        }
      }),
    })

    if (type === 'add' || type === 'clone') {
      dispatch(addProcessingRule({ ...newRule, Id: -Math.floor(Math.random() * (1000 + 1 - 1)) }))
    } else {
      aggregationPools.forEach((item: any) => {
        if (item.Name === newRule.LpPool) {
          const nameLp = item.Lps.map((item: any) => item.value)
          dispatch(changeAggregationPool({ ...item, bunchProfiles: [...item.bunchProfiles, newRule.Profile], Profile: newRule.Profile }, true))
          lps.forEach((item: any) => {
            if (nameLp.includes(item.Name)) {
              dispatch(changeExecutionLpOnePreset({ ...item, bunchProfiles: [...item.bunchProfiles, newRule.Profile] }))
            }
          })
        }
      })

      const newRoutingRule = routingRules.find((item: any) => item.ProcessorId === newRule.Id)
      if (newRoutingRule?.Profile === newRule.Profile) {
        dispatch(changeRoutingRuleNoChange({ ...newRoutingRule }))
      } else {
        dispatch(changeRoutingRuleNoChange({ ...newRoutingRule, ProcessorId: '', ProcessorName: '' }))
      }

      dispatch(changeProcessingRule(newRule))
    }
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`processing-rule.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [textInput('Name').errorMessage(alreadyExist()), selectInput('Profile', presetName).errorMessage(msg), selectInput('LpPool', (aggregationPools && aggregationPools.map((elem: AggregationPoolEntity) => elem.Name)) || [])],
          common,
          setCommon,
          'processing-rule',
          commonTouched,
          setCommonTouched,
          commonErrors,
        )}
        {buildControlsExtTwoPerLine(
          [
            textInput('MarkupFrom').disabled(inputState.ExecutionPriceSource === 'Platform'),
            textInput('MarkupTo').disabled(inputState.ExecutionPriceSource === 'Platform'),
            textInput('LimitMarkupFrom').disabled(inputState.ExecutionPriceSource === 'Platform'),
            textInput('LimitMarkupTo').disabled(inputState.ExecutionPriceSource === 'Platform'),
            selectInput('RoundType').optionItems(roundTypes).converter(roundTypesConverter(translate)),
            selectInput('ExecutionPriceSource').optionItems(['LP', 'Platform', 'TakePositiveSlippage']).converter(executionPriceSourceConverter(translate)).skipWhen(!gateway.EnablePriceOption),
            textInput('DelayFrom').skipWhen(inputState.ExecutionPriceSource !== 'TakePositiveSlippage'),
            textInput('DelayTo').skipWhen(inputState.ExecutionPriceSource !== 'TakePositiveSlippage'),
            checkboxInput('AllowContinuousExecutionForFOK'),
          ],
          inputState,
          setInputState,
          'processing-rule',
          touched,
          setTouched,
          errors,
        )}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default ProcessingRuleRightbar
