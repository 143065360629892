import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { AggregationPoolEntity, AggregationPoolType, IExecutorsPercent, isProroptionalPool } from '../../entity/configuration'
import { addAggregationPool, changeAggregationPool, changeExecutionLpOnePreset, executionConfigurationChanged } from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'
import { buildTableHeadersFromArray } from '../../utils/table-utils'
import MultiSelectInput from '../inputs/MultiSelectInput'
import SelectInput from '../inputs/SelectInput'
import TextInput from '../inputs/TextInput'
import * as yup from 'yup'
import { useFormValidation } from '../../hooks/useFormValidation'
import { buildControlsExtTwoPerLine, checkboxInput, selectInput, textInput } from '../../utils/controls'
import { AppAccordion, AppTable } from '@t4b/core/lib'
import { folderNameSchema } from '../../utils/schema-utils'
import { IRightbar } from './rightbar-types'
import { RootState } from '../../redux/reducers/rootReducer'
import CheckBoxInput from '../inputs/CheckBoxInput'

const percentSchema = {
  LPName: yup.string().required(),
  Percent: yup.number().integer().min(0).max(100).required(),
}

function validate(obj: IExecutorsPercent) {
  return {
    LPName: !percentSchema.LPName.isValidSync(obj.LPName),
    Percent: !percentSchema.Percent.isValidSync(obj.Percent),
  }
}

function sumIsWrong(percents: any[]): boolean {
  const sum = percents.reduce((sum: number, current: any) => sum + parseFloat(current.Percent), 0)
  return +sum.toFixed(2) !== 100.0
}

function isSwitchUnsupportedType(type: AggregationPoolType): boolean {
  if (type === AggregationPoolType.SingleBook || type === AggregationPoolType.MultiBook) {
    return false
  }
  return true
}

const schema = {
  Name: folderNameSchema(),
  Lps: yup.array().required(),
  AggregationType: yup.string().required(),
  ExecutorsPercents: yup.array().required(),
  Profile: yup.string().required(),
  AccumulateLpDeals: yup.boolean().required(),
  HideAggregationTimeout: yup
    .string()
    .matches(/^[0-9]+$/gi)
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
}

const AggregationPoolRightbar: React.FC<IRightbar> = ({ data: { type, item } }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(item, schema)
  const [percentState, setPercentState] = useState(item.ExecutorsPercents)
  const dispatch = useDispatch()
  const { aggregationPools, lps, Profile } = useSelector((state: RootState) => state.executionConfiguration)

  const [percentTouched, setPercentTouched] = useState<any>([])

  const percentErrors: any = []
  for (const percent of percentState) {
    percentErrors.push(validate(percent))
  }

  const nameLpPool = Profile.map((item: any) => item.Name)

  useEffect(() => {
    if (!inputState.Profile && type === 'add') {
      setInputState((prev: any) => {
        return {
          ...prev,
          Profile: nameLpPool[0] ?? '',
        }
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inputState.AggregationType === AggregationPoolType.MultiBook)
      if (inputState?.Lps?.length) {
        const searchTypeLps = lps?.filter((item: any) => {
          if (inputState?.Lps?.length ? item.Name === inputState?.Lps[0]?.value : item) return item
        })
        setInputState((prev: any) => {
          return {
            ...prev,
            Lps: prev.Lps.filter((item: any) => {
              if (searchTypeLps[0].Type === 'Bbook' || searchTypeLps[0].Type === 'BbookHedged' || searchTypeLps[0].Type === 'VolumeConverter') {
                return item.value !== searchTypeLps[0].Name
              } else {
                return item
              }
            }),
          }
        })
      }
  }, [inputState.AggregationType]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = () => {
    isValid()

    if (errors.HideAggregationTimeout) {
      return
    }

    const nameLpPool = inputState.Lps.map((item: any) => item.value)
    let data = null
    if (inputState.AggregationType === AggregationPoolType.MultiProportionalBook || inputState.AggregationType === AggregationPoolType.ProportionalExceptClose) {
      if (errors.Name || sumIsWrong(percentState) || percentErrors.find((errItem: any) => errItem.LPName || errItem.Percent)) {
        return
      }
      data = new AggregationPoolEntity({
        ...inputState,
        Name: inputState.Name.trim(),
        ExecutorsPercents: percentState,
        Lps: percentState.map((item: any) => ({
          value: item.LPName,
          label: item.LPName,
        })),
      })
    } else {
      if (errors.Name || errors.Type || errors.Lps) {
        return
      }
      data = new AggregationPoolEntity({ ...inputState, Name: inputState.Name.trim() })
    }
    if (type === 'clone') {
      lps.forEach((el: any) => {
        if (nameLpPool.includes(el.Name)) {
          el.flag = true
          const lpData = { ...el, bunchProfiles: el.bunchProfiles.filter((el: any) => el !== item.Profile) }
          dispatch(changeExecutionLpOnePreset({ ...lpData, bunchProfiles: [...lpData.bunchProfiles, inputState.Profile], Profile: '' }))
        }
      })
      dispatch(addAggregationPool({ ...data, bunchProfiles: [], Profile: item.Profile }))
    } else if (type === 'add') {
      lps.forEach((el: any) => {
        if (nameLpPool.includes(el.Name)) {
          el.flag = true
          const lpData = { ...el, bunchProfiles: el.bunchProfiles.filter((el: any) => el !== item.Profile) }
          dispatch(changeExecutionLpOnePreset({ ...lpData, bunchProfiles: [...lpData.bunchProfiles], Profile: '' }))
        }
      })
      dispatch(addAggregationPool({ ...data, bunchProfiles: [], Profile: item.Profile }))
    } else {
      lps.forEach((el: any) => {
        if (nameLpPool.includes(el.Name)) {
          el.flag = true
          const lpData = { ...el, bunchProfiles: el.bunchProfiles.filter((el: any) => el !== item.Profile) }
          dispatch(changeExecutionLpOnePreset({ ...lpData, bunchProfiles: [...lpData.bunchProfiles, inputState.Profile] }))
        }
      })
      dispatch(changeAggregationPool(data, false))
    }
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  let selectLps = null
  if (isProroptionalPool(inputState)) {
    const usedLp = percentState.filter((item: any) => item.LPName).map((item: any) => item.LPName)
    const newLp = lps.filter((item: any) => !usedLp.includes(item.Name))
    const filterDisabled = lps.filter((item: any) => !usedLp.includes(item.Name)).map((lp: any) => lp.Name)

    const handlePlus = () => {
      if (newLp.length) {
        setPercentState([...percentState, { LPName: (usedLp && newLp[0]?.Name) ?? '', Percent: 0 }])
      } else {
        return
      }
    }

    const percentages = percentState.map((elem: any, index: number) => {
      const handleDelete = () => {
        const newState = [...percentState]
        newState.splice(index, 1)
        setPercentState(newState)
      }

      const handleStateChange = (newState: any) => {
        const newStateArr = [...percentState]
        newStateArr.splice(index, 1, newState)
        setPercentState(newStateArr)
      }

      return (
        <tr key={index}>
          <td>
            <SelectInput state={percentState[index]} setState={handleStateChange} name="LPName" options={lps?.map((lp: any) => lp.Name) ?? []} className="m-0" filterDisabled={filterDisabled} />
          </td>
          <td>
            <TextInput state={percentState[index]} setState={handleStateChange} touched={percentTouched} setTouched={setPercentTouched} errors={percentErrors[index]} name="Percent" className="m-0" />
          </td>
          <td className="h-100">
            <Button variant="link" className="t4b-text-gray p-0 mt-1" onClick={handleDelete}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </td>
        </tr>
      )
    })

    percentages.push(
      <tr key="plus-row" className="cursor-pointer" onClick={handlePlus}>
        <td colSpan={3}>
          <FontAwesomeIcon icon={faPlus} />
        </td>
      </tr>,
    )

    const header = buildTableHeadersFromArray(
      ['Lp', 'Percent', 'Actions'].map((elem: string) => ({
        name: elem,
        show: true,
      })),
      'aggregation-pool',
    )

    const table = (
      <>
        <AppTable tableHeader={header} tableData={percentages} />
        <CheckBoxInput state={inputState} setState={setInputState} name="BWhenABookExecution" label="BWhenABookExecution" className="m-0 mb-4 check-feed" />
        <div className={`invalid-feedback ${sumIsWrong(percentState) && 'd-block'}`}>
          <FormattedMessage id="field-validation-error-100" />
        </div>
      </>
    )
    selectLps = (
      <AppAccordion
        item={{
          title: <FormattedMessage id="aggregation-pool.ExecutorsPercents" />,
          item: table,
        }}
        style={{ margin: '0 -20px', marginBottom: '1rem' }}
        isHidden={false}
      />
    )
  } else {
    const searchTypeLps = lps?.filter((item: any) => {
      if (inputState?.Lps?.length ? item.Name === inputState?.Lps[0]?.value : item) return item
    })

    const optionItems = buildMultiselectOptionsFromArray(
      lps
        .filter((item: any) => {
          if (inputState?.Lps?.length) {
            if (searchTypeLps[0]?.Type === 'Bbook') {
              return item.Type === 'Bbook'
            } else if (searchTypeLps[0]?.Type === 'BbookHedged') {
              return item.Type === 'BbookHedged'
            } else if (searchTypeLps[0]?.Type === 'VolumeConverter') {
              return item?.Type === 'VolumeConverter'
            } else {
              return item.Type !== 'BbookHedged' && item.Type !== 'Bbook' && item.Type !== 'VolumeConverter'
            }
          } else {
            return item
          }
        })
        .filter((lp: any) => {
          if (inputState.AggregationType === AggregationPoolType.MultiBook && lp.Type === 'VolumeConverter') {
            return null
          }
          return lp
        })
        .filter((lp: any) => {
          if (inputState.AggregationType === AggregationPoolType.MultiBook && lp.Type === 'Bbook') {
            return null
          }
          return lp
        })
        .filter((lp: any) => {
          if (inputState.AggregationType === AggregationPoolType.MultiBook && lp.Type === 'BbookHedged') {
            return null
          }
          return lp
        })
        .map((lp: any) => lp.Name),
    )

    selectLps = (
      <MultiSelectInput
        state={inputState}
        setState={setInputState}
        touched={touched}
        setTouched={setTouched}
        errors={errors}
        name="Lps"
        label="aggregation-pool.ExecutorsPercents"
        options={
          (searchTypeLps[0]?.Type === 'Bbook' && inputState?.Lps[0]?.value) || (searchTypeLps[0]?.Type === 'BbookHedged' && inputState?.Lps[0]?.value) || (searchTypeLps[0]?.Type === 'VolumeConverter' && inputState?.Lps[0]?.value)
            ? [optionItems.find((item: any) => item?.value === inputState?.Lps[0]?.value)]
            : optionItems
        }
      />
    )
  }

  const alreadyExist = () => {
    if ((type === 'add' || type === 'clone') && aggregationPools.find((pool: AggregationPoolEntity) => pool.Name === inputState.Name)) {
      errors.Name = true
      return 'aggregation-pool.exists'
    }
    return ''
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`aggregation-pool.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('Name')
              .disabled(type === 'modify')
              .errorMessage(alreadyExist()),
            selectInput('AggregationType', Object.values(AggregationPoolType), type !== 'add'),
          ],
          inputState,
          setInputState,
          'aggregation-pool',
          touched,
          setTouched,
          errors,
        )}

        {selectLps}

        {buildControlsExtTwoPerLine([checkboxInput('AccumulateLpDeals'), textInput('HideAggregationTimeout').skipWhen(!inputState.AccumulateLpDeals)], inputState, setInputState, 'aggregation-pool', touched, setTouched, errors)}

        {isSwitchUnsupportedType(inputState.AggregationType) && (
          <Alert variant="danger">
            <FormattedMessage id="aggregation-pool.unsupported-type" />
          </Alert>
        )}

        <Button className="t4b-bg-dark-button" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
}

export default AggregationPoolRightbar
