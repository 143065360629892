import { AppAccordion } from '@t4b/core/lib'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchMarginInfoUpdate, onlyFetchMarginInfoDepositHistory } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, textInput } from '../../utils/controls'
import MarginBalanceHistory from '../configuration/platforms/MarginBalanceHistory'
import { IRightbar } from './rightbar-types'

interface IState {
  Amount: number
  Comment: string
  AllowNegativeBalance: boolean
}

const INITIAL_STATE: IState = {
  Amount: 0,
  Comment: '',
  AllowNegativeBalance: false,
}

const schema = {
  Amount: yup.number().required(),
  Comment: yup.string().required(),
}

const MarginInfoRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const [deposit, setDeposit, touched, setTouched, errors, isValid] = useFormValidation(INITIAL_STATE, schema)
  const [depositHistory, setDepositHistory] = useState<any>({
    DepositHistory: [],
    Balance: 0,
  })
  const [withdrawPressed, setWithdrawPressed] = useState(false)

  useEffect(() => {
    onlyFetchMarginInfoDepositHistory({
      ...params,
      Login: item.Login,
    }).then((data: any) => setDepositHistory(data))
  }, [item.Login, params])

  const isBalanceInsufficient = () => {
    if (!deposit.AllowNegativeBalance) {
      if (parseFloat(deposit.Amount) > parseFloat(depositHistory.Balance)) {
        return true
      }
    }
    return false
  }

  const insufficientBalanceValidation = () => {
    if (withdrawPressed && isBalanceInsufficient()) {
      errors.Amount = true
      return 'margin-info.insufficient-balance'
    }

    return ''
  }

  const inputs = (
    <div>
      <h5>
        <FormattedMessage id="margin-info.Balance" />: {depositHistory.Balance}
      </h5>
      {buildControlsExtTwoPerLine(
        [checkboxInput('AllowNegativeBalance'), textInput('Amount').errorMessage(insufficientBalanceValidation()), textInput('Comment')],
        deposit,
        setDeposit,
        'margin-info',
        touched,
        setTouched,
        errors,
      )}
    </div>
  )

  const handleDeposit = () => {
    if (!isValid() && !errors.Amount) {
      return
    }
    dispatch(
      fetchMarginInfoUpdate({
        action: 'deposit',
        params: { ...params, Login: item.Login },
        body: deposit,
      }),
    )
    dispatch(hideRightBar())
  }

  const handleWithdraw = () => {
    if (!withdrawPressed) {
      setWithdrawPressed(true)
    }

    const cond1 = isBalanceInsufficient()
    const cond2 = !isValid()

    if (cond1 || cond2) {
      return
    }

    dispatch(
      fetchMarginInfoUpdate({
        action: 'withdraw',
        params: { ...params, Login: item.Login },
        body: deposit,
      }),
    )
    dispatch(hideRightBar())
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`margin-info.rightbar`} />,
          item: inputs,
        }}
        isHidden={false}
      />

      <MarginBalanceHistory history={depositHistory.DepositHistory} />

      <Button className="t4b-bg-dark-button my-3 mr-3 ml-20" onClick={handleDeposit}>
        <FormattedMessage id="deposit" tagName="span" />
      </Button>
      <Button className="t4b-bg-dark-button my-3" onClick={handleWithdraw}>
        <FormattedMessage id="withdraw" tagName="span" />
      </Button>
    </>
  )
})

export default MarginInfoRightbar
