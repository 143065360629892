import { AppAccordion, AppSwitch } from '@t4b/core/lib'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Range, getTrackBackground } from 'react-range'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { FeedPlatformRuleEntity, MarkupType } from '../../entity/feeding'
import { useFormValidation } from '../../hooks/useFormValidation'
import { addFeedPlatformSymbol, changeFeedPlatformSymbol, feedingConfigurationChanged, getFeedPlatformSymbols } from '../../redux/actions/feeding-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, cmselectInput, selectInput, textInput } from '../../utils/controls'
import { buildHTTPGetOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'
import { IRightbar } from './rightbar-types'

const FeedPlatformSymbolRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    { ...item, Multiplier: item.Multiplier ?? 1, IsDirection: item.Rounding !== 'Floor', Rounding: item.Rounding !== 'Floor' ? 'Ceil' : 'Floor', SpreadBalance: typeof item.SpreadBalance === 'string' ? [Number(item.SpreadBalance) + 1] : [1] },
    FeedPlatformRuleEntity.schema,
  )

  const [platformSymbols, setPlatformSymbols] = useState<any>([])
  const ref = useRef<any>(null)

  useEffect(() => {
    const url = new URL('/api/feedPlatform/symbols', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('feedPlatform', params.Platform)
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => setPlatformSymbols(buildMultiselectOptionsFromArray(data)))
      .catch((error: Error) => processError(error, dispatch))
  }, [params, dispatch])

  const handleSave = () => {
    if (!isValid()) {
      if (ref.current) {
        ref.current.open()
      }
      return
    }

    const SpreadBalanceMap = inputState.SpreadBalance?.map((item: number) => item - 1)

    if (type === 'add' || type === 'clone') {
      dispatch(addFeedPlatformSymbol({ params, body: { ...inputState, SpreadBalance: Number(SpreadBalanceMap.join('')), Rounding: inputState.Rounding !== 'Floor' ? 'Ceiling' : 'Floor' } }))
    } else if (type === 'modify') {
      dispatch(changeFeedPlatformSymbol({ params, body: { ...inputState, SpreadBalance: Number(SpreadBalanceMap.join('')), Rounding: inputState.Rounding !== 'Floor' ? 'Ceiling' : 'Floor' } }))
    }
    dispatch(getFeedPlatformSymbols({ ...inputState, SpreadBalance: Number(SpreadBalanceMap.join('')), Rounding: inputState.Rounding !== 'Floor' ? 'Ceiling' : 'Floor', ...params }))
    dispatch(feedingConfigurationChanged())
    dispatch(hideRightBar())
  }

  const controls = buildControlsExtTwoPerLine(
    [
      cmselectInput('Symbols', platformSymbols || [], '', 'Mask', true).title('Add mask:'),
      textInput('MaxLevels'),
      selectInput('MarkupType', Object.values(MarkupType)).converter((value: any) => {
        if (value === 'Proportional') {
          return 'Spread multiplier'
        } else {
          return value
        }
      }),
      textInput('MarkupBid').skipWhen(inputState.MarkupType === MarkupType.Proportional),
      textInput('MarkupAsk').skipWhen(inputState.MarkupType === MarkupType.Proportional),
      textInput('MinSpread')
        .skipWhen(inputState.MarkupType === MarkupType.Fixed)
        .skipWhen(inputState.MarkupType === MarkupType.Proportional),
      textInput('MaxSpread').skipWhen(inputState.MarkupType !== MarkupType.MiniMax),
      textInput('Multiplier').skipWhen(inputState.MarkupType !== MarkupType.Proportional),
    ],
    inputState,
    setInputState,
    'feed-platform.symbol-map',
    touched,
    setTouched,
    errors,
  )

  const handleSwitch = (checked: boolean) => {
    setInputState({
      ...inputState,
      IsDirection: checked,
      Rounding: checked ? 'Ceil' : 'Floor',
    })
  }

  const switcher =
    inputState.MarkupType === MarkupType.Proportional ? (
      <div className="mr-3">
        <div className="mb-2 d-flex align-items-center justify-content-center">
          <span>Rounding: </span>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <span className="mr-2">Floor</span>
          <AppSwitch checked={inputState.IsDirection} onChange={handleSwitch} />
          <span className="ml-2">
            <FormattedMessage id="Ceiling" />
          </span>
        </div>
      </div>
    ) : null

  const range =
    inputState.MarkupType === MarkupType.Proportional ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          margin: '2em',
        }}
      >
        <span>Spread balance:</span>
        <div className="d-flex align-items-center justify-content-between" style={{ width: '100%' }}>
          <span>Bid</span>
          <span>Ask</span>
        </div>
        <Range
          values={inputState.SpreadBalance}
          step={1}
          min={0}
          max={2}
          onChange={values =>
            setInputState((prev: any) => {
              return {
                ...prev,
                SpreadBalance: values,
              }
            })
          }
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '5px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values: inputState.SpreadBalance,
                    colors: ['#548BF4', '#ccc'],
                    min: 0,
                    max: 2,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '22px',
                width: '22px',
                borderRadius: '4px',
                backgroundColor: '#FFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 2px 6px #AAA',
              }}
            >
              <div
                style={{
                  height: '16px',
                  width: '5px',
                  backgroundColor: isDragged ? '#548BF4' : '#CCC',
                }}
              />
            </div>
          )}
        />
        <output style={{ marginTop: '30px' }} id="output"></output>
      </div>
    ) : null

  const wrapper = (
    <div className="d-flex flex-column position-relative">
      <div className="positionSwitch">{switcher}</div>
      <div style={{ width: '100%' }}>{range}</div>
    </div>
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`symbol-map.${type}`} />,
          item: [controls, wrapper],
        }}
        ref={ref}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
})

export default FeedPlatformSymbolRightbar
