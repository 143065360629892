import React, { FC, useEffect, useState } from 'react'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { Accordion, Alert, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { buildControlsExtTwoPerLine, sselectInput, textInput } from '../../../utils/controls'
import AppButton from '../../AppButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'
import { buildMultiselectOptionsFromArray } from '../../../utils/multiselect-utils'

import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons'
import { SyntheticSymbolsChildrenBar } from './SyntheticSymbolsChildrenBar'
import { fetchLpsSynteticSymbolsLPAdd, fetchLpsSynteticLPSymbolsModify, fetchLpSymbolMapSynthetic, fetchSyntheticSymbolsPreview } from '../../../redux/actions/system-settings-actions'
import { useLocation } from 'react-router-dom'
import { paginationDefault } from '../../../entity/pagination'
import { fetchGlobalSymbolMap } from '../../../redux/actions/platforms-actions'
import { hideRightBar, showRightBar } from '../../../redux/actions/rightbar-actions'
import { GlobalSymbol } from '../../../entity/system-settings'
import { transformEmptyStringMaxLength } from '../../../utils/schema-utils'
import { expToNum } from '../../../utils/num-utils'

export const SyntheticSymbolsBar: FC<any> = ({ data: { item, type, setLoading, params } }) => {
  const symbols = useSelector((state: any) => state.globalSymbolMap)
  const location = useLocation()
  const dispatch = useDispatch()
  const { gateway } = useSelector((state: RootState) => state.gateways)
  const { data, GlobalSymbolReserved } = useSelector((state: any) => state.sysLps.SymbolMapSynthetic)
  const { SyntheticSymbolsBid, SyntheticSymbolsAsk, SyntheticSymbolsMessage } = useSelector((state: any) => state.sysLps)

  const newSymbol = symbols?.data.map((item: any) => item.Symbol)
  const filterTypeDelete = newSymbol?.filter((item: any) => !GlobalSymbolReserved?.includes(item))
  const characterFilter = newSymbol.filter((item: any) => filterTypeDelete.includes(item))
  const buildMultiselectFromArray = buildMultiselectOptionsFromArray(characterFilter)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      Digits: String(item.Digits),
      GlobalSymbolName: !item?.GlobalSymbolName ? buildMultiselectFromArray[0] : { value: item.GlobalSymbolName, label: item.GlobalSymbolName },
      Children: item?.Children?.map((elem: any) => {
        return {
          ...elem,
          LastSaveAsk: typeof elem.LastSaveAsk === 'object' ? '' : String(expToNum(elem.LastSaveAsk)),
          LastSaveBid: typeof elem.LastSaveBid === 'object' ? '' : String(expToNum(elem.LastSaveBid)),
          InvalidationTimeout: String(expToNum(elem.InvalidationTimeout)),
          GlobalSymbolNameChildren: { value: elem?.GlobalSymbolNameChildren?.value ?? elem?.GlobalSymbolName, label: elem?.GlobalSymbolNameChildren?.value ?? elem?.GlobalSymbolName },
        }
      }),
    },
    {
      Digits: yup
        .string()
        .matches(/^[0-9]+$/gi)
        .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0 && value <= 28),

      Children: yup.array().of(
        yup
          .object()
          .shape({
            LastSaveAsk: yup
              .string()
              .matches(/^[0-9.]+$/gi)
              .transform((_: any, val: any) => String(val) || null)
              .transform(transformEmptyStringMaxLength)
              .notRequired()
              .nullable(),

            LastSaveBid: yup
              .string()
              .matches(/^[0-9.]+$/gi)
              .transform((_: any, val: any) => String(val) || null)
              .transform(transformEmptyStringMaxLength)
              .notRequired()
              .nullable(),

            FeedAggregationName: yup.string().required(),

            InvalidationTimeout: yup
              .string()
              .matches(/^[0-9]+$/gi)
              .transform((_: any, val: any) => String(val) || null)
              .transform(transformEmptyStringMaxLength)
              .notRequired()
              .nullable(),
          })
          .notRequired()
          .nullable(),
      ),
    },
  )

  const [hidden, setHidden] = useState(true)
  const [stateValid, setStateValid] = useState('')
  const [hiddenBidAsk, setHiddenBidAsk] = useState(false)

  useEffect(() => {
    if (type === 'add' && !item?.Children) {
      setInputState((prev: any) => {
        const newChildren = {
          FeedAggregationName: '',
          GlobalSymbolName: '',
          GlobalSymbolNameChildren: '',
          InvalidationTimeout: `${gateway.FeederInvalidationTimeout}`,
          IsInvalidation: true,
          LastSaveAsk: '',
          LastSaveBid: '',
          IsOpposite: false,
          Id: Math.round(1 - 0.5 + Math.random() * (9999 - 1 + 1)),
        }

        return {
          ...prev,
          Children: [newChildren],
          GlobalSymbolName: prev.GlobalSymbolName?.value ? buildMultiselectFromArray[0] : prev.GlobalSymbolName,
        }
      })
    }
  }, [type]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newDigits = symbols.data.find((item: any) => item.Symbol === inputState.GlobalSymbolName?.value)

    setInputState((prev: any) => {
      return { ...prev, Digits: newDigits?.Digits }
    })
  }, [inputState.GlobalSymbolName, symbols.data]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = async () => {
    if (!isValid() || !stateValid || inputState.Children.length === 0) {
      return
    }

    const body = {
      Id: inputState.Id,
      Description: inputState.Description,
      GlobalSymbolName: inputState.GlobalSymbolName?.value,
      Digits: inputState.Digits,
      Children: inputState.Children.map((item: any) => {
        return {
          FeedAggregationName: item.FeedAggregationName,
          GlobalSymbolName: item.GlobalSymbolNameChildren?.value,
          LastSaveBid: item.LastSaveBid,
          LastSaveAsk: item.LastSaveAsk,
          InvalidationTimeout: item.InvalidationTimeout,
          IsInvalidation: item.IsInvalidation,
          IsOpposite: item.IsOpposite,
        }
      }),
    }

    if (type === 'add') {
      await dispatch(
        fetchLpsSynteticSymbolsLPAdd({
          action: '',
          params: {
            Gateway: gateway.Name,
            lp: location.search.slice(4).replace('%20', ' '),
          },
          body: body,
        }),
      )
    } else {
      await dispatch(
        fetchLpsSynteticLPSymbolsModify({
          action: '',
          params: {
            Gateway: gateway.Name,
            lp: location.search.slice(4).replace('%20', ' '),
          },
          body: body,
        }),
      )
    }
    dispatch(hideRightBar())
    dispatch(
      fetchLpSymbolMapSynthetic({
        Gateway: gateway.Name,
        field: '',
        by: '',
        Page: paginationDefault.Page,
        Pages: paginationDefault.Pages,
        Count: paginationDefault.Count,
        lp: location.search.slice(4).replace('%20', ' '),
        setLoading: setLoading,
        ...params,
      }),
    )
    dispatch(
      fetchGlobalSymbolMap({
        ...inputState,
        Gateway: gateway.Name,
        Page: paginationDefault.Page,
        Count: 1000000,
        field: 'Symbol',
        by: 'asc',
      }),
    )
  }

  const handelAdd = () => {
    const newChildren = {
      FeedAggregationName: '',
      GlobalSymbolName: '',
      LastSaveBid: '',
      LastSaveAsk: '',
      InvalidationTimeout: `${gateway.FeederInvalidationTimeout}`,
      IsInvalidation: true,
      GlobalSymbolNameChildren: '',
      IsOpposite: false,
      Id: Math.round(1 - 0.5 + Math.random() * (9999 - 1 + 1)),
    }

    setInputState((prev: any) => {
      return {
        ...prev,
        Children: [...prev.Children, newChildren],
      }
    })
  }

  const addNewGlobalSymbol = () => {
    dispatch(
      showRightBar('globalSymbolMap', {
        type: 'add',
        params: {
          Gateway: gateway.Name,
          Page: paginationDefault.Page,
          Count: 1000000,
          field: '',
          by: '',
          Lps: gateway.Lps,
        },
        item: new GlobalSymbol(),
        SyntSymbols: async (params: any) => {
          const findGlobal = symbols?.data?.filter((item: any) => item.Symbol === params?.SyntSymbolsState?.GlobalSymbolName) // true
          const findSymbolOfGlobal = data?.filter((item: any) => item.GlobalSymbolName === params?.SyntSymbolsState?.GlobalSymbolName) // false

          const body = {
            ...params.SyntSymbolsState,
            GlobalSymbolName: findGlobal.length && findSymbolOfGlobal.length ? null : params?.SyntSymbolsState?.GlobalSymbolName,
          }

          dispatch(showRightBar('SynteticLpBar', { type: params.type, item: body }))
        },
        flag: true,
        SyntSymbolsState: inputState,
      }),
    )
  }

  const createFormulaIndex = inputState?.Children?.reduce((acc: any, item: any) => {
    if (item.IsOpposite) {
      acc += `inv(${item.GlobalSymbolNameChildren?.value}) * `
    } else {
      acc += `${item.GlobalSymbolNameChildren?.value} * `
    }

    return acc
  }, '')

  const withTooltipPreventOverflow = (item: any, text: string, key: any) => {
    const overlay = (
      <Tooltip id={`tooltip-${key}`}>
        <FormattedMessage id={text} />
      </Tooltip>
    )
    return (
      <OverlayTrigger key={key} overlay={overlay} placement="top">
        {item}
      </OverlayTrigger>
    )
  }

  return (
    <>
      <Card>
        <Card.Header className="color-dark font-500">
          <FormattedMessage id={type === 'add' ? 'SynteticLp.rightbar.add' : 'SynteticLp.rightbar.modify'} />
        </Card.Header>
        <Card.Body>
          <div className="wrp-pos mb-4">
            {buildControlsExtTwoPerLine([sselectInput('GlobalSymbolName', buildMultiselectFromArray), textInput('Digits'), textInput('Description')], inputState, setInputState, 'SyntheticSymbols', touched, setTouched, errors)}
            <div className="wrp-pos-btn">
              <AppButton variant="add-global-symbol" onClick={addNewGlobalSymbol} className="ml-auto mr-2  align-items-center" />
            </div>
          </div>
          <Accordion defaultActiveKey={hidden ? 'accordion' : undefined}>
            <Card className={`accordion-custom border-none`} style={{ overflow: 'visible' }}>
              <Card.Header className="d-flex justify-content-between p-0 accordion-custom__toggle border-none wordBreak" onClick={() => setHidden(prev => !prev)}>
                <Accordion.Toggle eventKey="accordion" className={`cursor-pointer d-flex align-items-center border-none font-500 flex-grow-1 text-wrap accordion-header-custom`} as="div">
                  <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                    <div>Source symbols</div>
                    <FontAwesomeIcon icon={faAngleRight} className={hidden ? 'icon ml-auto' : 'icon icon-rotate ml-auto'} />
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="accordion">
                <Card.Body className="accordion-custom__collapse accordion-collapse-color">
                  {inputState?.Children?.map((item: any, index: number) => {
                    item.Id = item.Id ? item.Id : Math.round(1 - 0.5 + Math.random() * (9999 - 1 + 1))

                    return (
                      <div key={item.Id} className="d-flex align-items-center justify-content-center">
                        <SyntheticSymbolsChildrenBar item={item} symbol={inputState?.GlobalSymbolName} data={inputState?.Children} index={index} setStateValid={setStateValid} setStateIndex={setInputState} indexValid={isValid} type={type} />
                      </div>
                    )
                  })}
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="cursor-pointer" onClick={handelAdd}>
                      <span className="color-checked color-checked-hov">Add symbol</span>
                      <FontAwesomeIcon icon={faPlus} className=" ml-2 cursor-pointer" />
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <div className="mb-4">
            Total symbols: <span className="color-checked">{inputState?.Children?.length}</span>
          </div>
          <div className="d-flex align-items-center justify-content-between ">
            <Button
              disabled={inputState?.Children?.length === 0}
              className="mr-3 btn-field-index"
              onClick={() => {
                setHiddenBidAsk(true)
                dispatch(
                  fetchSyntheticSymbolsPreview({
                    action: '',
                    params: {
                      Gateway: gateway.Name,
                    },
                    body: {
                      Digits: inputState.Digits,
                      GlobalSymbolName: inputState.GlobalSymbolName?.value,
                      Children: inputState.Children.map((item: any) => {
                        return {
                          FeedAggregationName: item.FeedAggregationName,
                          GlobalSymbolName: item.GlobalSymbolNameChildren?.value,
                          IsOpposite: item.IsOpposite,
                        }
                      }),
                    },
                  }),
                )
              }}
            >
              <FormattedMessage id="SyntheticSymbols" />
            </Button>
            {hiddenBidAsk && (
              <div className="d-flex align-items-center justify-content-between text-center ">
                <div className="name-quote">Bid:</div>
                <div className="mr-3 blockQuote border">
                  {`${SyntheticSymbolsBid}`.length >= 17 ? (
                    withTooltipPreventOverflow(<div className="text-quote">{typeof SyntheticSymbolsBid === 'object' ? '' : SyntheticSymbolsBid}</div>, `${SyntheticSymbolsBid}`, 'bid')
                  ) : (
                    <div className="text-quote">{typeof SyntheticSymbolsBid === 'object' ? '' : SyntheticSymbolsBid}</div>
                  )}
                </div>
                <div className="name-quote">Ask:</div>
                <div className="blockQuote border">
                  {`${SyntheticSymbolsAsk}`.length >= 17 ? (
                    withTooltipPreventOverflow(<div className="text-quote">{typeof SyntheticSymbolsAsk === 'object' ? '' : SyntheticSymbolsAsk}</div>, `${SyntheticSymbolsAsk}`, 'bid')
                  ) : (
                    <div className="text-quote">{typeof SyntheticSymbolsAsk === 'object' ? '' : SyntheticSymbolsAsk}</div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="mt-3">
            {SyntheticSymbolsMessage && (
              <Alert className="d-flex mb-0" variant="danger">
                <FormattedMessage id={SyntheticSymbolsMessage} />
              </Alert>
            )}
          </div>
          <div className="mt-4 ">
            <span className="color-checked">Synthetic symbol formula:</span>
            <div className="wpr-formula border">
              <div className="blockFormula d-flex align-items-center">
                <div>{createFormulaIndex?.slice(0, -3) ?? ''}</div>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-4">
            {inputState?.Children?.length === 0 && (
              <Alert className="d-flex mb-0" variant="danger">
                <FormattedMessage id={'Set source symbols before saving'} />
              </Alert>
            )}
          </div>
          <Button className="t4b-bg-dark-button mt-3" onClick={handleSave} disabled={inputState?.Children?.length === 0}>
            <FormattedMessage id="save" />
          </Button>
        </Card.Body>
      </Card>
    </>
  )
}
