import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { fetchDropCopySessionsUpdate } from '../../redux/actions/execution/drop-copy-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, textInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import { IRightbar } from './rightbar-types'
import { folderNameSchema } from '../../utils/schema-utils'
import { onlyFetchSendercompTargetcomp } from '../../redux/actions/platforms-actions'
import { processError } from '../../utils/fetch-utils'
import useGateway from '../../hooks/useGateway'

const schema = {
  SenderCompId: folderNameSchema(),
  TargetCompId: folderNameSchema(),
  BeginString: yup.string().required(),
  Username: yup.string().required(),
  Password: yup.string().required(),
}

const DropCopyRightbar: React.FC<IRightbar> = ({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const gateway = useGateway()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(item, schema)
  const [sendercompTargetcompId, setSendercompTargetcompId] = useState([{ SenderCompId: '', TargetCompId: '' }])

  useEffect(() => {
    onlyFetchSendercompTargetcomp({ ...params })
      .then((data: any) => setSendercompTargetcompId(data))
      .catch((error: Error) => processError(error, dispatch))
  }, [params, gateway, dispatch])

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    dispatch(hideRightBar())
    dispatch(fetchDropCopySessionsUpdate({ action: type, rule: inputState, params }))
  }

  const alreadyExist = () => {
    for (const item of sendercompTargetcompId) {
      if (item?.SenderCompId === inputState.SenderCompId && item?.TargetCompId === inputState.TargetCompId) {
        errors.SenderCompId = true
        errors.TargetCompId = true
        return 'Аlready exists'
      }
    }

    if (inputState.SenderCompId === inputState.TargetCompId) {
      errors.SenderCompId = true
      errors.TargetCompId = true
      return 'Аlready exists'
    }
    return ''
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`drop-copy.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('SenderCompId')
              .disabled(type === 'modify')
              .errorMessage(alreadyExist()),
            ,
            textInput('TargetCompId')
              .disabled(type === 'modify')
              .errorMessage(alreadyExist()),
            ,
            textInput('BeginString').disabled(true),
            textInput('Username'),
            textInput('Password'),
          ],
          inputState,
          setInputState,
          'drop-copy',
          touched,
          setTouched,
          errors,
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default DropCopyRightbar
