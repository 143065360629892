import '@t4b/core/lib/index.css'
import 'moment-timezone'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import 'react-datepicker/dist/react-datepicker.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import App from './App'
import rootReducer from './redux/reducers/rootReducer'
// import * as serviceWorker from './serviceWorker'
// import { createLogger } from 'redux-logger';

// const loggerMiddleware = createLogger();
const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      thunkMiddleware,
      // loggerMiddleware
    ),
  ),
)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// .serviceWorker
//   .unregister()
