import { blankInput, buildControlsExtTwoPerLine, checkboxInput, passwordInput, selectInput, textInput } from '../../../utils/controls'
import {
  binanceType,
  brandFieldSet,
  onBehalfOfCompIdFieldSet,
  partyIdFieldSet,
  queryIntervalFieldSet,
  quoteTextProcessingFieldSet,
  resubscribeOnRejectTimeoutFieldSet,
  saxoClientIdAccountNumber,
  senderSubIdFieldSet,
  targetBinanceFuturesFieldSet,
  targetSubIDFieldSet,
} from './lpType'

export const executionSession = (execState: any, setExecState: any, execTouched: any, setExecTouched: any, execErrors: any, type: string, inputState: any, gateway: any, sendercompTargetcompId: any) => {
  const alreadyExist = () => {
    for (const item of sendercompTargetcompId) {
      if (item?.SenderCompId === execState.SenderCompId && item?.TargetCompId === execState.TargetCompId && execState.SenderCompId.length !== 0 && execState.TargetCompId.length !== 0) {
        execErrors.SenderCompId = true
        execErrors.TargetCompId = true
        return 'Аlready exists'
      }
    }

    if (execState.SenderCompId === execState.TargetCompId && execState.SenderCompId.length !== 0 && execState.TargetCompId.length !== 0) {
      execErrors.SenderCompId = true
      execErrors.TargetCompId = true
      return 'Incorrect value'
    }
    return ''
  }
  return buildControlsExtTwoPerLine(
    [
      textInput('SenderCompId').errorMessage(alreadyExist()),
      textInput('TargetCompId').errorMessage(alreadyExist()),
      textInput('BeginString'),
      textInput('Username'),
      textInput('Password'),
      textInput('ReconnectInterval'),
      textInput('FileStorePath'),
      textInput('FileLogPath'),
      checkboxInput('WriteLogs'),
      textInput('SocketConnectHost'),
      textInput('SocketConnectPort'),
      textInput('MaxMessagesPerSec'),
      textInput('Account'),
      textInput('AccountId').skipWhen(!saxoClientIdAccountNumber.has(inputState.Type)),
      textInput('ClientId').skipWhen(!saxoClientIdAccountNumber.has(inputState.Type)),
      checkboxInput('SendRedundantResendRequests'),
      checkboxInput('ResetOnLogon'),
      checkboxInput('ResetOnLogout'),
      checkboxInput('ResetOnDisconnect'),
      checkboxInput('SslEnable'),
      textInput('SslCertificate').disabled(!execState.SslEnable).holder('optional'),
      passwordInput('SslCertificatePassword', type === 'add' ? 'setup' : 'change')
        .disabled(!execState.SslEnable)
        .holder('optional'),
      textInput('SslCaCertificate').disabled(!execState.SslEnable).holder('optional'),
      textInput('SslServerName').disabled(!execState.SslEnable).holder('optional'),
      textInput('Brand').skipWhen(!brandFieldSet.has(inputState.Type)),
      textInput('OnBehalfOfCompId').skipWhen(!onBehalfOfCompIdFieldSet.has(inputState.Type)),
      textInput('PartyId').skipWhen(!partyIdFieldSet.has(inputState.Type)),
      textInput('SenderSubId').skipWhen(!senderSubIdFieldSet.has(inputState.Type)),
      textInput('TargetSubID').skipWhen(!targetSubIDFieldSet.has(inputState.Type)),

      selectInput('SslProtocols', gateway?.SslProtocols ?? [])
        .disabled(!execState.SslEnable)
        .textMessages(execState.SslProtocols === 'SSL 2.0' || execState.SslProtocols === 'SSL 3.0' ? 'Protocol is deprecated. Not all systems support it, the connection may not work!' : ''),
      ,
    ],
    execState,
    setExecState,
    'lp',
    execTouched,
    setExecTouched,
    execErrors,
  )
}

export const feedingSession = (feedingState: any, setFeedingState: any, feedingTouched: any, setFeedingTouched: any, feedingErrors: any, type: string, inputState: any, gateway: any, sendercompTargetcompId: any) => {
  const alreadyExist = () => {
    for (const item of sendercompTargetcompId) {
      if (item?.SenderCompId === feedingState.SenderCompId && item?.TargetCompId === feedingState.TargetCompId && feedingState.SenderCompId.length !== 0 && feedingState.TargetCompId.length !== 0) {
        feedingErrors.SenderCompId = true
        feedingErrors.TargetCompId = true
        return 'Аlready exists'
      }
    }

    if (feedingState.SenderCompId === feedingState.TargetCompId && feedingState.SenderCompId.length !== 0 && feedingState.TargetCompId.length !== 0) {
      feedingErrors.SenderCompId = true
      feedingErrors.TargetCompId = true
      return 'Incorrect value'
    }
    return ''
  }

  return buildControlsExtTwoPerLine(
    [
      textInput('SenderCompId').errorMessage(alreadyExist()),
      textInput('TargetCompId').errorMessage(alreadyExist()),
      textInput('BeginString'),
      textInput('Username'),
      textInput('Password'),
      textInput('ReconnectInterval'),
      textInput('FileStorePath'),
      textInput('FileLogPath'),
      checkboxInput('WriteLogs'),
      textInput('SocketConnectHost'),
      textInput('SocketConnectPort'),
      checkboxInput('ResetOnLogon'),
      checkboxInput('ResetOnLogout'),
      checkboxInput('ResetOnDisconnect'),
      checkboxInput('SslEnable'),
      textInput('SslCertificate').disabled(!feedingState.SslEnable).holder('optional'),
      passwordInput('SslCertificatePassword', type === 'add' ? 'setup' : 'change')
        .disabled(!feedingState.SslEnable)
        .holder('optional'),
      textInput('SslCaCertificate').disabled(!feedingState.SslEnable).holder('optional'),
      textInput('SslServerName').disabled(!feedingState.SslEnable).holder('optional'),
      selectInput('SslProtocols', gateway?.SslProtocols ?? [])
        .disabled(!feedingState.SslEnable)
        .textMessages(feedingState.SslProtocols === 'SSL 2.0' || feedingState.SslProtocols === 'SSL 3.0' ? 'Protocol is deprecated. Not all systems support it, the connection may not work!' : ''),
      ,
      blankInput(),
      textInput('QueryInterval').skipWhen(!queryIntervalFieldSet.has(inputState.Type)),
      textInput('TargetSubID').skipWhen(!targetSubIDFieldSet.has(inputState.Type)),
      textInput('ResubscribeOnRejectTimeout').skipWhen(!resubscribeOnRejectTimeoutFieldSet.has(inputState.Type)),
      selectInput('IgnoreLogOptions', ['Nothing', 'Quote', 'Other']),
      checkboxInput('QuoteTextProcessing').skipWhen(!quoteTextProcessingFieldSet.has(inputState.Type)),
    ],
    feedingState,
    setFeedingState,
    'lp',
    feedingTouched,
    setFeedingTouched,
    feedingErrors,
  )
}

export const laverate = (leverateState: any, setLeverateState: any, leverateTouched: any, setLeverateTouched: any, leverateErrors: any, inputState: any) => {
  return buildControlsExtTwoPerLine(
    [
      textInput('ApiKey'),
      textInput('ApiSecret'),
      textInput('LogPath').disabled(!leverateState.EnableFeederLogging),
      textInput('ReconnectTimeout'),
      checkboxInput('EnableFeederLogging'),
      textInput('WebServiceUrl').skipWhen(inputState.Type === 'Leverate'),
      textInput('WebServicePort')
        .skipWhen(inputState.Type === 'MorningStar')
        .skipWhen(inputState.Type === 'Leverate'),
    ],
    leverateState,
    setLeverateState,
    'leverate',
    leverateTouched,
    setLeverateTouched,
    leverateErrors,
  )
}

export const alpaca = (alpacaState: any, setAlpacaState: any, alpacaTouched: any, setAlpacaTouched: any, alpacaErrors: any) => {
  return buildControlsExtTwoPerLine([textInput('ApiKey'), textInput('ApiSecret'), checkboxInput('Live')], alpacaState, setAlpacaState, 'lp', alpacaTouched, setAlpacaTouched, alpacaErrors)
}

export const synthetic = (syntheticState: any, setSyntheticState: any, syntheticTouched: any, setSyntheticTouched: any, syntheticErrors: any) => {
  return buildControlsExtTwoPerLine([textInput('QuoteRefreshTimeout')], syntheticState, setSyntheticState, 'lp', syntheticTouched, setSyntheticTouched, syntheticErrors)
}

export const hedgingPool = (hedgingBBookState: any, setHedgingBBookState: any, hedgingBBookTouched: any, setHedgingBBookTouched: any, errors: any, pool: any) => {
  return buildControlsExtTwoPerLine([selectInput('AggregationPool').optionItems(pool).errorMessage('Incorrect value')], hedgingBBookState, setHedgingBBookState, 'lp', hedgingBBookTouched, setHedgingBBookTouched, errors)
}

export const LMAXCrypto = (LMAXCryptoOneState: any, setLMAXCryptoOneState: any, LMAXCryptoOneTouched: any, setLMAXCryptoOneTouched: any, LMAXCryptoOneErrors: any) => {
  return buildControlsExtTwoPerLine([checkboxInput('Enabled'), textInput('CheckInterval'), textInput('PriceDocumentLink')], LMAXCryptoOneState, setLMAXCryptoOneState, 'LMAXCrypto', LMAXCryptoOneTouched, setLMAXCryptoOneTouched, LMAXCryptoOneErrors)
}

export const binanceInputsOne = (binanceState: any, setBinanceState: any, binanceTouched: any, setBinanceTouched: any, binanceErrors: any, inputState: any) => {
  return buildControlsExtTwoPerLine(
    [
      textInput('AllowedCancellationsForUser').skipWhen(inputState?.Type?.value === 'Huobi'),
      textInput('AllowedCancellationsForSession').skipWhen(inputState?.Type?.value === 'Huobi'),
      textInput('ReconnectTimeout'),
      textInput('CancellationRatingResetTimeout').skipWhen(inputState?.Type?.value === 'Huobi'),
      textInput('LogPath'),
      textInput('TasksLimit').skipWhen(inputState?.Type?.value === 'Huobi'),
      textInput('ReceiveWindow').skipWhen(inputState?.Type?.value === 'Huobi'),
      checkboxInput('ConfirmClosingOrdersUnderMinNotional')
        .skipWhen(inputState?.Type?.value === 'BinanceStock')
        .skipWhen(inputState?.Type?.value === 'Huobi'),
      checkboxInput('EnableFeederLogging'),
    ],
    binanceState,
    setBinanceState,
    'lp',
    binanceTouched,
    setBinanceTouched,
    binanceErrors,
  )
}

export const binanceInputsTwo = (binanceState: any, setBinanceState: any, binanceTouched: any, setBinanceTouched: any, binanceErrors: any, FuturesAllowedValues: any, StockAllowedValues: any, inputState: any, type: any) => {
  return buildControlsExtTwoPerLine(
    [
      textInput('ApiKey'),
      passwordInput('ApiSecret').setMode(type),
      selectInput('DepthLevels', binanceType(inputState) === 'BinanceFutures' ? FuturesAllowedValues?.DepthLevels : StockAllowedValues?.DepthLevels).skipWhen(inputState?.Type?.value === 'Huobi'),
      selectInput('QuotesUpdateInterval', binanceType(inputState) === 'BinanceFutures' ? FuturesAllowedValues?.QuotesUpdateIntervals : StockAllowedValues?.QuotesUpdateIntervals).skipWhen(inputState?.Type?.value === 'Huobi'),
      textInput('Leverage').skipWhen(!targetBinanceFuturesFieldSet.has(inputState?.Type?.value)),
      checkboxInput('StreamLastPrices').skipWhen(inputState?.Type?.value === 'Huobi'),
    ],
    binanceState,
    setBinanceState,
    inputState?.Type?.value === 'Huobi' ? 'huobi' : 'lp',
    binanceTouched,
    setBinanceTouched,
    binanceErrors,
  )
}
