import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { OrderSide, OrderType, ProcessingRuleItem, roundTypes } from '../../entity/configuration'
import { useFormValidation } from '../../hooks/useFormValidation'
import { useTranslate } from '../../hooks/useTranslate'
import { addProcessingRuleItem, changeProcessingRuleItem, executionConfigurationChanged, getProcessingRuleItems } from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import { blankInput, buildControlsExtTwoPerLine, checkboxInput, executionPriceSourceConverter, mselectInput, roundTypesConverter, selectInput, textInput } from '../../utils/controls'
import { buildMultiselectOptionsFromArray, buildMultiselectOptionsFromEnum, buildOptionsWithAll, isAllOption, isEqualArrays, optionsToStrings } from '../../utils/multiselect-utils'
import { IRightbar } from './rightbar-types'

const ProcessingRuleItemRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    new ProcessingRuleItem({
      ...item,
      MinVolume: String(item.MinVolume),
      MaxVolume: String(item.MaxVolume),
    }),
    ProcessingRuleItem.schema,
  )
  const { gateway } = useSelector((state: RootState) => state.gateways)
  const handleSave = () => {
    if (!isValid()) {
      return
    }
    const rule = new ProcessingRuleItem(inputState)
    if (type === 'add' || type === 'clone') {
      dispatch(addProcessingRuleItem({ rule, params, Profile: params.profile }))
    } else {
      dispatch(changeProcessingRuleItem({ rule, params, Profile: params.profile }))
    }
    dispatch(getProcessingRuleItems({ ...params }))
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  const setOrderTypesState = (state: any) => {
    const all = 'All'

    if (
      state.OrderTypes?.find(isAllOption) ||
      isEqualArrays(
        optionsToStrings(state.OrderTypes)
          .filter(option => option !== all)
          .sort(),
        Object.keys(OrderType)
          .filter(option => option !== all)
          .sort(),
      )
    ) {
      setInputState({
        ...state,
        OrderTypes: buildMultiselectOptionsFromArray([all]),
      })
      return
    }
    setInputState(state)
  }

  if (+inputState.MinVolume > 1000000000 || inputState.MinVolume.slice(-1) === '.') {
    errors.MinVolume = true
  }

  if (+inputState.MaxVolume > 1000000000 || inputState.MaxVolume.slice(-1) === '.') {
    errors.MaxVolume = true
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`processing-rule.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('Symbols', false, '', '', 'Mask', true),
            textInput('Logins', false, '', '', 'Mask', true),
            textInput('Groups', false, '', '', 'Mask', true),
            textInput('MinVolume'),
            textInput('MaxVolume'),
            textInput('Security', false, '', '', 'Mask', true),
            mselectInput('OrderTypes', buildOptionsWithAll(buildMultiselectOptionsFromEnum(OrderType), inputState.OrderTypes)).stateSetup(setOrderTypesState),
            selectInput('OrderSides').optionItems(Object.keys(OrderSide)),
            blankInput(),
            textInput('MarkupFrom').disabled(inputState.ExecutionPriceSource === 'Platform'),
            textInput('MarkupTo').disabled(inputState.ExecutionPriceSource === 'Platform'),
            textInput('LimitMarkupFrom').disabled(inputState.ExecutionPriceSource === 'Platform'),
            textInput('LimitMarkupTo').disabled(inputState.ExecutionPriceSource === 'Platform'),
            selectInput('RoundType').optionItems(roundTypes).converter(roundTypesConverter(translate)),
            selectInput('ExecutionPriceSource').optionItems(['LP', 'Platform', 'TakePositiveSlippage']).converter(executionPriceSourceConverter(translate)).skipWhen(!gateway.EnablePriceOption),
            textInput('DelayFrom').skipWhen(inputState.ExecutionPriceSource !== 'TakePositiveSlippage'),
            textInput('DelayTo').skipWhen(inputState.ExecutionPriceSource !== 'TakePositiveSlippage'),
            checkboxInput('AllowContinuousExecutionForFOK'),
            checkboxInput('Enabled'),
          ],
          inputState,
          setInputState,
          'processing-rule',
          touched,
          setTouched,
          errors,
        )}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default ProcessingRuleItemRightbar
