import { Dispatch } from 'redux'
import { throwErrorMessage, throwSuccessMessage } from '../../utils/errors-utils'
import { buildHTTPGetOptions, buildHTTPPostOptions, checkResponse, fetchBuilder, modifyBuilder, onlyFetchBuilder, processError } from '../../utils/fetch-utils'
import { Action } from './actions'

function buildURL(pathname: string, params: any): URL {
  const url = new URL(pathname, document.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('platform', params.Platform)
  if (params.Session) {
    url.searchParams.set('session', params.Session)
  }
  url.searchParams.set('pool', params.CurrentPool)
  url.searchParams.set('symbol', params.Symbol)
  url.searchParams.set('accounts', params.Accounts)
  url.searchParams.set('groups', params.Groups)
  url.searchParams.set('page', params.Page)
  url.searchParams.set('count', params.Count)
  if (params.field.length && params.by !== 'none') {
    url.searchParams.set('sort', params.field)
    url.searchParams.set('by', params.by)
  }
  return url
}

export function onlyFetchNewSwitch(params: any) {
  const url = buildURL('/api/riskTool/newSwitch/positions', params)
  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
}

export function fetchNewSwitch(params: any) {
  const url = buildURL('/api/riskTool/newSwitch/positions', params)
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => dispatch({ type: 'NEW_SWITCH', data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchSwitchSelectedPositions(params: any, selectedPositions: any) {
  const url = new URL('/api/riskTool/newSwitch', document.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('platform', params.Platform)

  const req = {
    NewProcessor: params.NewProcessor,
    FromPool: params.CurrentPool,
    ToPool: params.TargetPool,
    Symbols: Array.from(selectedPositions.values())
      .map((values: any) => {
        return {
          Symbol: values.Symbol,
          Accounts: Array.from(selectedPositions.values())
            // eslint-disable-next-line array-callback-return
            .map((login: any) => {
              if (login.Symbol === values.Symbol) return login.Login
            })
            .filter((item: any) => item),
        }
      })

      .filter((item, index, arr) => index === arr.findIndex(elem => elem.Symbol === item.Symbol)),
  }

  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions(req))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        // eslint-disable-next-line array-callback-return
        result.Status.map((statusCode: any) => {
          if (statusCode.Result) {
            throwErrorMessage(`Symbol:${statusCode.Symbol} - ${statusCode.Description}`, true)
            return new Promise((resolve, reject) => reject(new Error(`api.result.${statusCode.Status} Symbol:${statusCode.Symbol}`)))
          } else {
            throwSuccessMessage(`Symbol:${statusCode.Symbol} - Successfully`)
          }
        })
        return onlyFetchNewSwitch(params)
      })
      .then((data: any) => dispatch({ type: 'NEW_SWITCH', data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export const onlyFetchActiveSwitches = onlyFetchBuilder('/api/riskTool/activeSwitches', {
  Platform: true,
  ActivePool: true,
  OriginalPool: true,
  Symbol: true,
  Page: true,
  Count: true,
})
export const fetchActiveSwitches = fetchBuilder(onlyFetchActiveSwitches, 'ACTIVE_SWITCHES')
export const fetchActiveSwitchesReturn = modifyBuilder('/api/riskTool/activeSwitches', onlyFetchActiveSwitches, 'ACTIVE_SWITCHES', {
  Platform: true,
  Id: true,
})

export function fetchActiveRules(params: any) {
  const url = new URL('/api/riskTool/activeRules', document.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('platform', params.Platform)
  url.searchParams.set('page', params.Page)
  url.searchParams.set('count', params.Count)
  if (params.field.length && params.by !== 'none') {
    url.searchParams.set('sort', params.field)
    url.searchParams.set('by', params.by)
  }
  if (params.Search) {
    url.searchParams.set('search', params.Search)
  }

  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => dispatch({ type: 'ACTIVE_RULES', data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchRiskToolHistory(params: any) {
  const url = new URL('/api/riskTool/history', document.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('platform', params.Platform)
  url.searchParams.set('activePool', params.ActivePool)
  url.searchParams.set('originalPool', params.OriginalPool)
  url.searchParams.set('symbol', params.Symbol.value)
  url.searchParams.set('timeFilter', params.TimeFilter)
  url.searchParams.set('from', params.From)
  url.searchParams.set('to', params.To)
  url.searchParams.set('page', params.Page)
  url.searchParams.set('count', params.Count)

  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => dispatch({ type: 'RISK_HISTORY', data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export const onlyFetchAutoSwitch = onlyFetchBuilder('/api/riskTool/autoSwitch', { Platform: true, Page: true, Count: true })
export const fetchAutoSwitch = fetchBuilder(onlyFetchAutoSwitch, 'AUTO_SWITCH')
export const fetchAutoSwitchUpdate = modifyBuilder('/api/riskTool/autoSwitch', onlyFetchAutoSwitch, 'AUTO_SWITCH', { Platform: true })
export const fetchAutoSwitchDelete = modifyBuilder('/api/riskTool/autoSwitch', onlyFetchAutoSwitch, 'AUTO_SWITCH', { Platform: true, Id: true })
