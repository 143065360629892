import moment from 'moment'
import React from 'react'
import { Badge } from 'react-bootstrap'
import InfoItem from '../components/InfoItem'

export function buildInfoItems(items: any[], srcObj: any, translatePrefix: string, variant?: string): JSX.Element[] {
  return items.map((item: any, index: number) => {
    const translateKey = typeof item === 'string' ? `${translatePrefix}.${item}` : `${translatePrefix}.${item.name}`
    const classNameBg = (item: any) => {
      if (srcObj[item] === 'BUY') {
        return 'bg-buy'
      } else if (srcObj[item] === 'Buy') {
        return 'bg-buy'
      } else if (srcObj[item] === 'Sell') {
        return 'bg-sell'
      } else if (srcObj[item] === 'SELL') {
        return 'bg-sell'
      } else if (`${srcObj[item]}` === 'true') {
        return 'bg-profit-minus badge badge-pill'
      }
    }
    if (typeof item === 'string') {
      if (item.length === 0) {
        return <InfoItem variant={variant} key={Math.random()} first={''} second={''} />
      }
      return <InfoItem variant={variant} key={item} first={translateKey} second={srcObj[item]} />
    } else {
      // const val = Number.parseFloat(srcObj[item.name])
      switch (item.type) {
        case 'price':
          return (
            <InfoItem
              variant={variant}
              key={item.name}
              first={translateKey}
              second={
                <Badge pill={true} className="bg-price">
                  {srcObj[item.name]}
                </Badge>
              }
            />
          )
        case 'direction':
          return (
            <InfoItem
              variant={variant}
              key={item.name}
              first={translateKey}
              second={
                <Badge pill={true} className={classNameBg(item.name)}>
                  {`${srcObj[item.name]}` === 'true' ? 'Yes' : `${srcObj[item.name]}` === 'false' ? 'No' : srcObj[item.name]}
                </Badge>
              }
            />
          )
        case 'profit':
          return (
            <InfoItem
              variant={variant}
              key={item.name}
              first={translateKey}
              second={
                <Badge pill={true} className={srcObj[item.name] === 0 ? 'bg-profit-plus' : 'bg-profit-minus'}>
                  {srcObj[item.name] === 0 ? 0 : srcObj[item.name]}
                </Badge>
              }
            />
          )
        case 'time':
          return <InfoItem variant={variant} key={item.name} first={translateKey} second={moment(srcObj[item.name]).utc().format('YYYY-MM-DD HH:mm:ss')} />
        default:
          return <InfoItem variant={variant} key={item} first={translateKey} second={srcObj[item.name]} />
        case 'list':
          return <InfoItem variant={variant} key={item.name} first={translateKey} second={srcObj[item.name].toString()} />
        case 'timespan':
          return <InfoItem variant={variant} key={item.name} first={translateKey} second={moment(srcObj[item.name]).utc().format('HH:mm:ss')} />
        case 'checkbox':
          return <InfoItem variant={variant} key={Math.random()} first={translateKey} second={srcObj[item.name] ? 'true' : 'false'} />
        case 'password':
          return <InfoItem variant={variant} key={item} first={translateKey} second="********" />
        case 'ms':
          return <InfoItem variant={variant} key={item.name} first={translateKey} second={formatMs(srcObj[item.name])} />
      }
    }
  })
}

function formatMs(ms: number | undefined): string {
  if (typeof ms === 'number') {
    return `${ms} ms`
  }
  return 'n/a'
}

export function infoPassword(item: string): any {
  return {
    name: item,
    type: 'password',
  }
}

export function infoPrice(item: string): any {
  return {
    name: item,
    type: 'price',
  }
}

export function infoDirection(item: string): any {
  return {
    name: item,
    type: 'direction',
  }
}

export function infoProfit(item: string): any {
  return {
    name: item,
    type: 'profit',
  }
}

export function infoTime(item: string): any {
  return {
    name: item,
    type: 'time',
  }
}

export function infoTimespan(item: string): any {
  return {
    name: item,
    type: 'timespan',
  }
}

export function infoMs(item: string): any {
  return {
    name: item,
    type: 'ms',
  }
}

export function infoCheckbox(item: string): any {
  return {
    name: item,
    type: 'checkbox',
  }
}

export function infoList(item: string): any {
  return {
    name: item,
    type: 'list',
  }
}

export function buildVolumeDistribution(arr: any[], platform?: any) {
  if (!Array.isArray(arr)) {
    return <div />
  }

  const classNameBg = (item: any) => {
    if (item === 'BUY') {
      return 'bg-buy badge badge-pill'
    } else if (item === 'Buy') {
      return 'bg-buy badge badge-pill'
    } else if (item === 'Sell') {
      return 'bg-sell badge badge-pill'
    } else if (item === 'SELL') {
      return 'bg-sell badge badge-pill'
    }
  }

  return (
    <div className="row">
      {platform === 'MT4Deals' ? (
        <div className="col-2">
          <div style={{ fontSize: 13 }} className="info-item default mb-3 justify-content-center align-items-center d-flex font-weight-bold">
            Pool
          </div>
          {arr.map((item: any) => (
            <div className="info-item default justify-content-center align-items-center d-flex">{item.Pool}</div>
          ))}
        </div>
      ) : platform === 'MT4Positions' ? null : (
        <div className="col-4">
          {arr.map((item: any) => (
            <InfoItem key={Math.random()} first="position.Pool" second={item.Pool} l={4} r={8} />
          ))}
        </div>
      )}

      {platform === 'MT5Positions' || platform === 'FixPositions' || platform === 'MT4Positions' ? null : <div className="border mr-2" />}
      {platform === 'MT4Positions' || platform === 'MT4Deals' ? (
        <div className="col-3">
          <div style={{ fontSize: 13 }} className="info-item default mb-3 justify-content-center align-items-center d-flex font-weight-bold">
            LP
          </div>
          {arr.map((item: any) => (
            <div className="info-item default justify-content-center align-items-center d-flex">{item.Lp}</div>
          ))}
        </div>
      ) : platform === 'MT5Positions' || platform === 'FixPositions' ? null : (
        <div className="col-4 ml-3">
          {arr.map((item: any) => (
            <InfoItem key={Math.random()} first="position.Lp" second={item.Lp || 'N/A'} l={4} r={8} />
          ))}
        </div>
      )}

      {platform === 'MT4Positions' || platform === 'MT4Deals' ? <div className="border" /> : null}
      {platform === 'MT4Positions' || platform === 'MT4Deals' ? (
        <div className={platform === 'MT4Positions' ? 'col-2 mr-2 ml-2 d-flex flex-column align-items-center' : 'col-1 mr-2 ml-2'}>
          <div style={{ fontSize: 13 }} className="info-item default mb-3 justify-content-center align-items-center d-flex font-weight-bold">
            Direction
          </div>
          {arr.map((item: any) => (
            <div style={{ fontSize: 9, height: 14, width: 36, marginBottom: 5 }} className={`info-item default justify-content-center align-items-center d-flex ${classNameBg(item.Direction)}`}>
              {item.Direction}
            </div>
          ))}
        </div>
      ) : null}

      <div className="border" />

      {platform === 'MT4Positions' || platform === 'MT4Deals' ? (
        <div className="col-3" style={{ width: 50 }}>
          <div style={{ fontSize: 13 }} className="info-item default mb-3 justify-content-center align-items-center d-flex font-weight-bold">
            Volume (units)
          </div>
          {arr.map((item: any) => (
            <div className="info-item default justify-content-center align-items-center d-flex">{item.Volume}</div>
          ))}
        </div>
      ) : (
        <div className="col-3 ml-3">
          {arr.map((item: any) => (
            <InfoItem key={Math.random()} className={item.Direction === 'BUY' ? 'bg-buy' : 'bg-sell'} first="position.Volume" second={item.Volume} />
          ))}
        </div>
      )}

      {platform === 'MT4Positions' || platform === 'MT4Deals' ? <div className="border" /> : null}
      {platform === 'MT4Positions' || platform === 'MT4Deals' ? (
        <div className="col-2 mr-2 ml-2">
          <div style={{ fontSize: 13 }} className="info-item default mb-3 justify-content-center align-items-center d-flex font-weight-bold ">
            Mt4 order
          </div>
          {arr.map((item: any) => (
            <div style={{ height: 15, width: 33, marginRight: 'auto', marginLeft: 'auto', marginBottom: 5 }} className={`info-item default justify-content-center align-items-center d-flex`}>
              {item.Mt4Order}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
