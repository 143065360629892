import { AppAccordion } from '@t4b/core/lib'
import React, { useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ConnectionString } from '../../entity/configuration'
import { convertPlatformFields, FixApiOZPlatformEntity, FixApiPlatformEntity } from '../../entity/platforms'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useDefaultDB } from '../../hooks/useDefaultDbSettings'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchAddPlatform, modifyPlatform } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, textInput, passwordInput, selectInput, timespanInput } from '../../utils/controls'
import DbConnectionEditor from '../configuration/system/DbConnectionEditor'

interface IFixApiPlatformEdit extends IGatewayProp {
  data: any
  isValidParent?: () => boolean
}

const FixApiPlatformEdit: React.FC<IFixApiPlatformEdit> = ({ data: { item, type, port }, isValidParent, gateway, gatewayName }) => {
  const fixPlatform = item.Type === 'fixapi' ? new FixApiPlatformEntity(item) : new FixApiOZPlatformEntity(item)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(fixPlatform, FixApiPlatformEntity.schema())
  const [connectionString, setConnectionString, connTouched, setConnTouched, connErrors, isConnValid] = useFormValidation(new ConnectionString(item.ConnectionString || {}), ConnectionString.schema(type))

  const [inputStateStatements, setInputStateStatements, touchedStatements, setTouchedStatements, errorsStatements, isValidStatements] = useFormValidation(
    { ...inputState, StatementsTime: inputState.StatementsType === 'Disabled' ? 0 : inputState.StatementsTime },
    {},
  )

  const dispatch = useDispatch()
  const ref = useRef<any>(null)
  const refS = useRef<any>(null)
  const dbRef = useRef<any>(null)
  useEffect(() => {
    setInputState({
      ...inputState,
      Name: item.Name,
      FileStorePath: item.FileStorePath,
      FileLogPath: item.FileLogPath,
    })
    setConnectionString({
      ...connectionString,
      Database: item.ConnectionString.Database,
    })
  }, [item.Name]) // eslint-disable-line react-hooks/exhaustive-deps

  const [isDefaultSettings, setDefaultDb] = useDefaultDB(type, connectionString, setConnectionString, gatewayName, inputState)

  const dbEditor = <DbConnectionEditor type={type} state={connectionString} setState={setConnectionString} touched={connTouched} setTouched={setConnTouched} errors={connErrors} isDefaultSettings={isDefaultSettings} setIsDefaultSettings={setDefaultDb} />

  const statements = buildControlsExtTwoPerLine(
    [
      selectInput('StatementsType').optionItems(['Disabled', 'Daily']),
      selectInput('StatementsMode')
        .optionItems(['HTML', 'CSV'])
        .skipWhen(inputStateStatements.StatementsType === 'Disabled'),
      timespanInput('StatementsTime').skipWhen(inputStateStatements.StatementsType === 'Disabled'),
    ],
    inputStateStatements,
    setInputStateStatements,
    'statements',
    touchedStatements,
    setTouchedStatements,
    errorsStatements,
  )
  const newPort = port.filter((elem: any) => elem !== item.SocketAcceptPort)
  const alreadyExist = () => {
    if (port.map(String).includes(String(inputState.SocketAcceptPort)) && type === 'add') {
      errors.SocketAcceptPort = true
      return 'Аlready exists'
    }

    if (newPort.map(String).includes(String(inputState.SocketAcceptPort))) {
      errors.SocketAcceptPort = true
      return 'Аlready exists'
    }
    return ''
  }

  const editPlatform = buildControlsExtTwoPerLine(
    [textInput('SocketAcceptPort').errorMessage(alreadyExist()), checkboxInput('UseLocalTime'), textInput('FileStorePath'), textInput('FileLogPath')],
    inputState,
    setInputState,
    'platform.fix',
    touched,
    setTouched,
    errors,
  )

  const ssl = buildControlsExtTwoPerLine(
    [
      checkboxInput('SSLEnable'),
      textInput('SslCertificate').disabled(!inputState.SSLEnable).holder('optional'),
      passwordInput('SslCertificatePassword', type === 'add' ? 'setup' : 'change')
        .disabled(!inputState.SSLEnable)
        .holder('optional'),
      textInput('SslCaCertificate').disabled(!inputState.SSLEnable).holder('optional'),
      textInput('SslServerName').disabled(!inputState.SSLEnable).holder('optional'),
      selectInput('SslProtocols', gateway?.SslProtocols ?? [])
        .disabled(!inputState.SSLEnable)
        .textMessages(inputState.SslProtocols === 'SSL 2.0' || inputState.SslProtocols === 'SSL 3.0' ? 'Protocol is deprecated. Not all systems support it, the connection may not work!' : ''),
      checkboxInput('SslCheckCertificateRevocation'),
      checkboxInput('SslRequireClientCertificate'),
      checkboxInput('SslValidateCertificates'),
    ],
    inputState,
    setInputState,
    'platform.fix',
    touched,
    setTouched,
    errors,
  )

  const handleSave = () => {
    const cond1 = isValid()
    let cond2 = isConnValid()

    if (isValidParent) {
      let validName = isValidParent()
      if (!validName) {
        return
      }
    }

    if (!cond1) {
      ref?.current?.open()
    }

    if (!cond2) {
      dbRef?.current?.open()
    }

    if (!cond1 || !cond2) {
      return
    }

    dispatch(hideRightBar())
    if (type === 'add') {
      dispatch(
        fetchAddPlatform({
          action: 'add',
          params: { Gateway: gateway.Name },
          body: {
            ...convertPlatformFields(inputState),
            ConnectionString: connectionString,
            StatementsTime: inputStateStatements.StatementsTime,
            StatementsType: inputStateStatements.StatementsType,
            StatementsMode: inputStateStatements.StatementsMode,
          },
        }),
      )
    } else if (type === 'modify') {
      dispatch(
        modifyPlatform({
          action: 'modify',
          params: { Gateway: gateway.Name },
          body: {
            ...convertPlatformFields(inputState),
            ConnectionString: connectionString,
            StatementsTime: inputStateStatements.StatementsTime,
            StatementsType: inputStateStatements.StatementsType,
            StatementsMode: inputStateStatements.StatementsMode,
          },
        }),
      )
    }
  }

  return (
    <>
      <AppAccordion
        item={{
          title: (
            <FormattedMessage
              id={item.Type === 'fixapi' ? 'platform.rightbar.edit.fix' : 'platform.rightbar.edit.fixOz'}
              values={item.Type === 'fixapi' ? { Type: type === 'add' ? 'FIX-API' : `Modify ${item.Name} (FIX-API)` } : { Type: type === 'add' ? 'FIX-TypeO' : `Modify ${item.Name} (FIX-TypeO)` }}
            />
          ),
          item: editPlatform,
        }}
        ref={ref}
        isHidden={false}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.conn-string" />,
          item: dbEditor,
        }}
        ref={dbRef}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.ssl" />,
          item: ssl,
        }}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.Statements" />,
          item: statements,
        }}
        ref={refS}
        render={refS.current}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
}

export default withGateway(FixApiPlatformEdit)
