export class AutoSwitchEntity {
  Id: number
  RuleName: string
  Groups: string
  Accounts: string
  Symbol: string
  NewProcessor: string
  OriginalPool: string
  SwitchToPool: string
  SwitchTriggerType: string
  SwitchTriggerDirection: string
  SwitchTriggerValue: number
  ReturnTriggerType: string
  ReturnTriggerDirection: string
  ReturnTriggerValue: number
  AggregatedSwitch: boolean

  constructor(item: any) {
    this.Id = item.Id ?? 0
    this.RuleName = item.RuleName
    this.Groups = item.Groups
    this.Accounts = item.Accounts
    this.Symbol = item.Symbol
    this.NewProcessor = item.NewProcessor
    this.OriginalPool = item.OriginalPool
    this.SwitchToPool = item.SwitchToPool
    this.SwitchTriggerType = item.SwitchTriggerType || 'Currency'
    this.SwitchTriggerDirection = item.SwitchTriggerDirection || 'Greater'
    this.SwitchTriggerValue = item.SwitchTriggerValue ?? 0
    this.ReturnTriggerType = item.ReturnTriggerType || 'Currency'
    this.ReturnTriggerDirection = item.ReturnTriggerDirection || 'Greater'
    this.ReturnTriggerValue = item.ReturnTriggerValue ?? 0
    this.AggregatedSwitch = item.AggregatedSwitch ?? false
  }
}
