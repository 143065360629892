import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { buildHTTPGetOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { buildInfoItems, buildVolumeDistribution, infoDirection, infoMs, infoPrice, infoProfit, infoTime } from '../../utils/info-item-utils'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'

const MT4Deals: React.FC<IRightbar> = ({
  data: {
    Gateway,
    Platform,
    position: { OrderId },
  },
}) => {
  const dispatch = useDispatch()
  const [position, setPosition] = useState<any>({})

  useEffect(() => {
    const url = new URL('/api/deal', window.location.origin)
    url.searchParams.set('gateway', Gateway)
    url.searchParams.set('platform', Platform)
    url.searchParams.set('id', OrderId)
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => setPosition(data))
      .catch((error: Error) => processError(error, dispatch))
  }, [Gateway, OrderId, Platform, dispatch])

  const additionalComponent = (
    <div className="row">
      <div className="col-6">{buildInfoItems(['ContractSize', 'Comment', '', infoDirection('OpenIsSwitch')], position, 'mt4deals')}</div>
      <div className="col-6">{buildInfoItems(['Digits', 'UserName', 'UserGroup', infoDirection('CloseIsSwitch')], position, 'mt4deals')}</div>
    </div>
  )

  return (
    <>
      <Card>
        <Card.Header
          style={{
            color: 'rgb(141, 171, 196)',
            fontWeight: 500,
            fontSize: '13px',
            textTransform: 'uppercase',
          }}
        >
          <FormattedMessage id="order-detail" />
        </Card.Header>
        <Card.Body>
          <div className="row mb-3">
            <div className="col-6">
              {buildInfoItems(
                [
                  'OrderId',
                  'UserId',
                  infoDirection('Direction'),
                  infoPrice('OpenRequestPrice'),
                  infoPrice('OpenPrice'),
                  'OpenSlippage',
                  'TakeProfit',
                  '',
                  infoTime('OpenRequestTime'),
                  infoTime('OpenTime'),
                  '',
                  infoProfit('TraderProfit'),
                  '',
                  '',
                  'OpenExternalId',
                  infoMs('OpenLpExecutionTime'),
                  infoMs('OpenFullExecutionTime'),
                  'OpenLpPriceSlippage',
                  'OpenTpMarkup',
                  infoProfit('OpenTpBrokerProfit'),
                  infoProfit('OpenTotalBrokerProfit'),
                ],
                position,
                'mt4deals',
              )}
            </div>
            <div className="col-6">
              {buildInfoItems(
                [
                  'Symbol',
                  'Volume',
                  'LotVolume',
                  infoPrice('CloseRequestPrice'),
                  infoPrice('ClosePrice'),
                  'CloseSlippage',
                  'StopLoss',
                  '',
                  infoTime('CloseRequestTime'),
                  infoTime('CloseTime'),
                  '',
                  infoProfit('BrokerProfit'),
                  'TpPriceAdjustment',
                  '',
                  'CloseExternalId',
                  infoMs('CloseLpExecutionTime'),
                  infoMs('CloseFullExecutionTime'),
                  'CloseLpPriceSlippage',
                  'CloseTpMarkup',
                  'CloseTotalMarkup',
                  infoProfit('CloseTpBrokerProfit'),
                  infoProfit('CloseTotalBrokerProfit'),
                ],
                position,
                'mt4deals',
              )}
            </div>
          </div>
        </Card.Body>
      </Card>

      <AppAccordion item={{ title: 'Additional', item: additionalComponent }} style={{ fontSize: '13px' }} isHidden={false} />

      <AppAccordion
        item={{
          title: 'Volume distribution',
          item: buildVolumeDistribution(position.Distribution, 'MT4Deals'),
        }}
        style={{ fontSize: '13px' }}
        isHidden={false}
        render={position?.Distribution?.length}
      />
    </>
  )
}

export default MT4Deals
