import { Action } from '../actions/actions'

function tradingHistoryReducer(
  state = {
    deals: [],
    totalCount: 0,
  },
  action: any,
) {
  switch (action.type) {
    case Action.FlushDeals:
      return {
        deals: [],
        totalCount: 0,
      }
    case Action.GotDeals:
      return {
        ...state,
        deals: action.data.Data,
        totalCount: action.data.TotalCount,
      }
    default:
      return state
  }
}

export default tradingHistoryReducer
