import React, { useEffect, useState } from 'react'
import { buildControlsExtTwoPerLine, passwordInput, selectInput, textInput } from '../../../utils/controls'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { LMAXCryptoConnectorsClass, LMAXCryptoCredentialsClass } from '../../../entity/system-settings'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

interface CryptoConnector {
  connector: any
  setLMAXCryptoOneState: (cb: any) => void
  type: string
  LMAXCryptoOneState: any
  filterType: string[]
  LMAXCryptoConnectors: (cb: any) => void
  LMAXCryptoCredentials: (cb: any) => void
  count: number
  filterTypeDelete: string[]
  setRenderCount: (cb: any) => void
  renderCount: number
}

const convert = (option: string) => {
  switch (option) {
    case 'MetaTrader4':
      return 'MT4'
    case 'MetaTrader5':
      return 'MT5'
    case 'FixApi':
      return 'FIX-API'
    case 'ExtApi':
      return 'EXT-Platform'
  }
}

const funcValid = (type: string, disabled: boolean, flag: boolean) => {
  if (type && disabled && flag === undefined) {
    return yup.string()
  } else {
    return yup.string().required()
  }
}

export const LMAXCryptoConnector: React.FC<CryptoConnector> = ({
  connector,
  setLMAXCryptoOneState,
  type,
  LMAXCryptoOneState,
  filterType,
  LMAXCryptoConnectors,
  LMAXCryptoCredentials,
  count,
  filterTypeDelete,
  setRenderCount,
  renderCount,
}): any => {
  const [flag, setFlag] = useState(false)

  const LMAXCryptoConnectorsSchema = {
    ConnectorName: yup.string().required(),
    GroupMask: yup.string().required(),
    SymbolMask: yup.string().required(),
  }

  const [
    LMAXCryptoConnectorsState,
    setLMAXCryptoConnectorsState,
    LMAXCryptoConnectorsTouched,
    setLMAXCryptoConnectorsTouched,
    LMAXCryptoConnectorsErrors,
    isLMAXCryptoConnectorsValid,
  ] = useFormValidation(new LMAXCryptoConnectorsClass(connector), !LMAXCryptoOneState.Enabled ? {} : LMAXCryptoConnectorsSchema)

  const LMAXCryptoCredentialsSchema = {
    Login: yup.number().required(),
    ServerIP: yup
      .string()
      .matches(/^[0-9.]+$/gi)
      .required(),
    ServerOwner: yup.string(),
    ServerName: LMAXCryptoConnectorsState.ConnectorType === 'MetaTrader4' ? yup.string().required() : yup.string(),
    Password: funcValid(type, LMAXCryptoConnectorsState.disabled, flag),
  }

  const [
    LMAXCryptoCredentialsState,
    setLMAXCryptoCredentialsState,
    LMAXCryptoCredentialsTouched,
    setLMAXCryptoCredentialsTouched,
    LMAXCryptoCredentialsErrors,
    isLMAXCryptoCredentialsValid,
  ] = useFormValidation(
    new LMAXCryptoCredentialsClass(connector.Credentials),
    LMAXCryptoConnectorsState.ConnectorType === 'FixApi' || LMAXCryptoConnectorsState.ConnectorType === 'ExtApi'
      ? {}
      : !LMAXCryptoOneState.Enabled
      ? {}
      : LMAXCryptoCredentialsSchema,
  )

  if (type === 'modify' && !flag && LMAXCryptoConnectorsState.disabled) {
    LMAXCryptoCredentialsErrors.Password = false
    LMAXCryptoCredentialsTouched.Password = false
  } else if (LMAXCryptoConnectorsState.disabled) {
    LMAXCryptoCredentialsErrors.Password = true
    LMAXCryptoCredentialsTouched.Password = true
  }

  useEffect(() => {
    if (renderCount) {
      setLMAXCryptoConnectorsState(connector)
      setLMAXCryptoCredentialsState(connector.Credentials)
    }
  }, [LMAXCryptoOneState.ConnectorParameters.Connectors.length]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFlag(LMAXCryptoCredentialsState.flag)
    setLMAXCryptoOneState((prev: any) => {
      return {
        ...prev,
        ConnectorParameters: {
          Connectors: [
            ...prev.ConnectorParameters.Connectors.map((item: any) => {
              if (item.Id === connector.Id) {
                item = { ...LMAXCryptoConnectorsState, Credentials: LMAXCryptoCredentialsState }
                return new LMAXCryptoConnectorsClass(item)
              } else {
                return item
              }
            }),
          ],
        },
      }
    })
  }, [LMAXCryptoConnectorsState, LMAXCryptoCredentialsState]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (count) {
      LMAXCryptoConnectors(isLMAXCryptoConnectorsValid())
      LMAXCryptoCredentials(isLMAXCryptoCredentialsValid())
    }
  }, [count]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = (id: any) => {
    setRenderCount((prev: any) => prev + 1)

    setLMAXCryptoOneState((prev: any) => {
      return {
        ...prev,
        ConnectorParameters: {
          Connectors: prev.ConnectorParameters.Connectors.filter((item: any, i: number) => item.Id !== id),
        },
      }
    })
  }

  const filterFixApi = () => LMAXCryptoConnectorsState.ConnectorType === 'FixApi' || LMAXCryptoConnectorsState.ConnectorType === 'ExtApi'
  const filterMT4 = () => LMAXCryptoConnectorsState.ConnectorType !== 'MetaTrader4'

  return (
    <div>
      <FontAwesomeIcon icon={faPlus} className="closeBtn cursor-pointer" onClick={() => handleDelete(connector.Id)} />
      {buildControlsExtTwoPerLine(
        [
          textInput('ConnectorName'),
          selectInput('ConnectorType')
            .optionItems(filterType)
            .converter(convert)
            .filter(filterTypeDelete)
            .disabled(type !== 'add' && LMAXCryptoConnectorsState.disabled),
          textInput('GroupMask'),
          textInput('SymbolMask'),
        ],
        LMAXCryptoConnectorsState,
        setLMAXCryptoConnectorsState,
        'LMAXCrypto',
        LMAXCryptoConnectorsTouched,
        setLMAXCryptoConnectorsTouched,
        LMAXCryptoConnectorsErrors,
      )}
      {buildControlsExtTwoPerLine(
        [
          textInput('ServerIP').skipWhen(filterFixApi),
          textInput('ServerName').skipWhen(filterMT4),
          textInput('ServerOwner').skipWhen(filterFixApi),
          textInput('Login').skipWhen(filterFixApi),
          passwordInput('Password', type === 'add' ? 'setup' : type !== 'add' && LMAXCryptoConnectorsState.disabled ? 'change' : 'setup').skipWhen(
            filterFixApi,
          ),
        ],
        LMAXCryptoCredentialsState,
        setLMAXCryptoCredentialsState,
        'LMAXCrypto',
        LMAXCryptoCredentialsTouched,
        setLMAXCryptoCredentialsTouched,
        LMAXCryptoCredentialsErrors,
      )}
    </div>
  )
}
