import { Action } from '../actions/actions'
import { round } from '../../utils/num-utils'

class ExposureState {
  public exposure: any
  public currencies: string[]
  public lps: string[]

  constructor() {
    this.exposure = {
      positions: [],
      volume: [],
      profit: [],
    }
    this.currencies = []
    this.lps = []
  }
}

function roundProfit(profit: any) {
  for (const key in profit) {
    profit[key] = round(profit[key])
  }

  return profit
}

function exposureReducer(state = new ExposureState(), action: any) {
  switch (action.type) {
    case Action.ExposureInitial:
      return new ExposureState()
    case Action.GotExposure:
      return {
        ...state,
        exposure: {
          ...action.data,
          profit: roundProfit(action.data.profit),
        },
      }
    case Action.GotExposureCurrencies:
      return {
        ...state,
        currencies: action.data,
      }
    case Action.GotExposureLps:
      return {
        ...state,
        lps: action.data,
      }
    default:
      return state
  }
}

export default exposureReducer
