import React from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useTranslate } from '../../hooks/useTranslate'
import '../../App.scss'

interface ISelectItems {
  options: string[]
  label?: string | null
  name: string
  state: any
  touched?: any
  errors?: any
  errorText?: string
  className?: string
  translateItems?: boolean
  isDisabled?: boolean
  style?: any
  flag?: boolean
  errMsgText?: any
  filterDisabled?: string[]
  convert?(option: any): string

  setState(state: any): any

  setTouched?(touched: any): any
}

const SelectInput: React.FC<ISelectItems> = ({ className, label, name, options, state, errMsgText, setState, translateItems, isDisabled, convert, errorText, style, flag, filterDisabled, errors, setTouched, touched }) => {
  const translate = useTranslate()
  const optionComponents = React.useMemo(
    () => {
      if (filterDisabled) {
        return options?.map((option: string) => {
          if (filterDisabled?.find(item => item === option)) {
            return (
              <option key={option} value={option} className={flag ? 'first-hiden' : ''}>
                {translateItems ? translate(option) : convert ? convert(option) : option}
              </option>
            )
          } else {
            return (
              <option key={option} disabled value={option} className={flag ? 'first-hiden' : ''}>
                {translateItems ? translate(option) : convert ? convert(option) : option}
              </option>
            )
          }
        })
      } else {
        return options?.map((option: string) => {
          return (
            <option key={option} value={option} className={flag ? 'first-hiden' : ''}>
              {translateItems ? translate(option) : convert ? convert(option) : option}
            </option>
          )
        })
      }
    },
    [options, filterDisabled], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleChange = (event: any) => {
    return setState({
      ...state,
      [name]: event.target.value,
    })
  }

  const handleBlur = () => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  return (
    <Form.Group className={className}>
      {Boolean(label) && (
        <Form.Label>
          <FormattedMessage id={label as string} tagName="span" />
          <span>:</span>
        </Form.Label>
      )}
      <div className={errMsgText ? 'd-flex justify-content-between align-items-center' : ''}>
        <Form.Control as="select" name={name} value={state[name]} onChange={handleChange} disabled={!!isDisabled} style={style} isInvalid={touched && touched[name] && errors && errors[name]} onBlur={handleBlur}>
          {optionComponents}
        </Form.Control>
        <div className={errMsgText ? 'colorText' : ''}>{errMsgText}</div>
      </div>
      <Form.Control.Feedback className={options.length ? '' : 'd-block'} type="invalid">
        <span>{errorText || errMsgText}</span>
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default SelectInput
