import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { AutoSwitchEntity } from '../../entity/risk-tool-entity'
import { useFormValidation } from '../../hooks/useFormValidation'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { fetchAutoSwitchUpdate } from '../../redux/actions/risk-tool-actions'
import { blankInput, buildControlsExtTwoPerLine, buildPoolsInputSwitch, checkboxInput, selectInput, textInput } from '../../utils/controls'
import { optionsToStrings } from '../../utils/multiselect-utils'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'
import { isMixedPool, isSwitchUnsupportedPoolType, typeOfPool } from '../../utils/aggregation-pool'

const schema = {
  RuleName: yup.string().required(),
  Groups: yup.string().required(),
  Accounts: yup.string().required(),
  NewProcessor: yup.string().required(),
  SwitchTriggerValue: yup.number().required(),
  ReturnTriggerValue: yup.number().required(),
}

enum SwitchTriggerType {
  Currency = 'Currency',
  Points = 'Points',
  BalancePercent = 'BalancePercent',
}

enum SwitchTriggerDirection {
  Greater = 'Greater',
  Smaller = 'Smaller',
}

function convert(option: string) {
  if (option === SwitchTriggerType.Currency) {
    return 'PnL in acc currency'
  }
  if (option === SwitchTriggerType.Points) {
    return 'Price change in points'
  }
  if (option === SwitchTriggerType.BalancePercent) {
    return 'PnL percent of balance'
  }
  return option
}

const AutoSwitchRightbar: React.FC<IRightbar> = ({ data: { type, item, params } }) => {
  const [settings, setSettings] = useState({
    Gateway: '',
    Symbols: [],
  })

  const dispatch = useDispatch()
  const [poolToProcessors, setPoolToProcessors] = useState(new Map())
  const { gateway } = useSelector((state: any) => state.gateways)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new AutoSwitchEntity(item), schema)
  const { data } = useSelector((state: any) => state.riskTool.autoSwitch)
  const mainRef = useRef<any>(null)
  const switchRef = useRef<any>(null)
  const returnRef = useRef<any>(null)

  useEffect(() => {
    if (gateway.Name) {
      const poolToProc = new Map()
      for (const pair of gateway?.Processors) {
        if (poolToProc.has(pair.Pool)) {
          poolToProc.set(pair.Pool, [...poolToProc.get(pair.Pool), pair.Name])
        } else {
          poolToProc.set(pair.Pool, [pair.Name])
        }
      }

      const activePool = gateway.Pools[0] ?? null
      let targetPool = null
      if (activePool) {
        if (activePool.Type === 'B-book') {
          targetPool = gateway.Pools.find((pool: any) => pool.Type !== 'B-book')
        } else {
          targetPool = gateway.Pools.find((pool: any) => pool.Type === 'B-book')
        }
      }

      const platformObject = gateway.Platforms.find((platform: any) => platform.Name === params.Platform)
      const newState = {
        Gateway: gateway.Name,
        OriginalPool: activePool?.Name,
        SwitchToPool: targetPool?.Name,
        NewProcessor: targetPool && poolToProc.get(targetPool.Name) ? poolToProc.get(targetPool.Name)[0] : '' || '',
        Symbols: optionsToStrings(platformObject?.Symbols) || [],
      }

      setPoolToProcessors(poolToProc)
      setSettings({
        ...inputState,
        ...newState,
      })

      if (type === 'add') {
        setInputState({
          ...inputState,
          Gateway: gateway.Name,
          OriginalPool: activePool?.Name,
          SwitchToPool: targetPool?.Name,
          NewProcessor: targetPool && poolToProc.get(targetPool.Name) ? poolToProc.get(targetPool.Name)[0] : '' || '',
          Symbol: optionsToStrings(platformObject?.Symbols)[0] ?? '',
        })
      }
    }
  }, [gateway]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type === 'clone') {
      setInputState((prev: any) => {
        return {
          ...prev,
          RuleName: '',
        }
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isUnsupportedCurrentPool = isSwitchUnsupportedPoolType(typeOfPool(gateway, inputState.OriginalPool)) || isMixedPool(gateway, inputState.OriginalPool)
  const isUnsupportedTargetPool = isSwitchUnsupportedPoolType(typeOfPool(gateway, inputState.SwitchToPool)) || isMixedPool(gateway, inputState.SwitchToPool)

  const switchTrigger = buildControlsExtTwoPerLine(
    [
      selectInput('SwitchTriggerType').optionItems(Object.values(SwitchTriggerType)).converter(convert),
      blankInput(),
      selectInput('SwitchTriggerDirection', Object.values(SwitchTriggerDirection)),
      textInput('SwitchTriggerValue'),
    ],
    inputState,
    setInputState,
    'auto-switch',
    touched,
    setTouched,
    errors,
  )

  const returnTrigger = buildControlsExtTwoPerLine(
    [
      selectInput('ReturnTriggerType').optionItems(Object.values(SwitchTriggerType)).converter(convert),
      blankInput(),
      selectInput('ReturnTriggerDirection', Object.values(SwitchTriggerDirection)),
      textInput('ReturnTriggerValue'),
    ],
    inputState,
    setInputState,
    'auto-switch',
    touched,
    setTouched,
    errors,
  )

  const setOriginalPool = (newState: any) => {
    const updatedState = { ...newState }
    updatedState.SwitchToPool = buildPoolsInputSwitch([], gateway, newState.OriginalPool)[0]
    updatedState.NewProcessor = poolToProcessors.get(updatedState.SwitchToPool) ? poolToProcessors.get(updatedState.SwitchToPool)[0] : ''
    setInputState(updatedState)
  }

  const setProcessor = (newState: any) => {
    const updatedState = { ...newState }
    updatedState.NewProcessor = poolToProcessors.get(newState.SwitchToPool) ? poolToProcessors.get(newState.SwitchToPool)[0] : ''
    setInputState(updatedState)
  }

  const alreadyExist = () => {
    if ((type === 'add' || type === 'clone') && data.find((item: any) => item.RuleName === inputState.RuleName)) {
      errors.RuleName = true
      return 'auto-switch.already-exist'
    }
    return ''
  }

  const test = () => {
    if (type === 'add' || type === 'modify' || type === 'clone') {
      return errors.NewProcessor ? 'Incorrect value' : ''
    }
    return ''
  }

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('RuleName').errorMessage(alreadyExist()),
      textInput('Groups', false, '', '', 'Mask', true),
      textInput('Accounts', false, '', '', 'Mask', true),
      selectInput('Symbol', settings.Symbols),
      selectInput('OriginalPool', gateway?.Pools?.map((pool: any) => pool.Name) ?? [], false, inputState, setOriginalPool),
      selectInput('SwitchToPool', buildPoolsInputSwitch([], gateway, inputState.OriginalPool), false, inputState, setProcessor),
      selectInput('NewProcessor', poolToProcessors.get(inputState.SwitchToPool) ?? []).errorMessage(test()),
      checkboxInput('AggregatedSwitch'),
    ],
    inputState,
    setInputState,
    'auto-switch',
    touched,
    setTouched,
    errors,
  )

  const handleClick = () => {
    if (!isValid() || isUnsupportedCurrentPool || isUnsupportedTargetPool) {
      at(prev => prev + 1)
      if (mainRef.current) {
        mainRef.current.open()
      }
      if (errors.SwitchTriggerValue && switchRef.current) {
        switchRef.current.open()
      }
      if (errors.ReturnTriggerValue && returnRef.current) {
        returnRef.current.open()
      }

      return
    }

    const FindId = gateway.Processors.filter((item: any) => item?.Name === inputState.NewProcessor || item?.Id === inputState.NewProcessor)
    dispatch(fetchAutoSwitchUpdate({ action: type, body: { ...inputState, NewProcessor: FindId[0]?.Id }, params }))
    dispatch(hideRightBar())
  }

  const [t, st] = useState<number>(0)
  const [a, at] = useState<number>(0)
  useEffect(() => {
    st(prev => prev + 1)
  }, [a])

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={type === 'clone' ? 'auto-switch.rightbar.title.clone' : 'auto-switch.rightbar.title'} />,
          item: inputs,
        }}
        render={a || t}
        ref={mainRef}
        isHidden={false}
      />

      {(isUnsupportedCurrentPool && (
        <Alert className="d-flex mb-0" variant="danger">
          <FormattedMessage id="new-switch.unsupported-pool" />
        </Alert>
      )) ||
        (isUnsupportedTargetPool && (
          <Alert className="d-flex mb-0" variant="danger">
            <FormattedMessage id="new-switch.unsupported-pool" />
          </Alert>
        ))}
      <AppAccordion
        item={{
          title: <FormattedMessage id="auto-switch.rightbar.switch-trigger" />,
          item: switchTrigger,
        }}
        ref={switchRef}
      />
      <AppAccordion
        item={{
          title: <FormattedMessage id="auto-switch.rightbar.return-trigger" />,
          item: returnTrigger,
        }}
        ref={returnRef}
      />
      <Button
        className="t4b-bg-dark-button my-3 ml-20"
        onClick={handleClick}
        onChange={() => {
          at(prev => prev + 1)
          st(prev => prev + 1)
        }}
      >
        <FormattedMessage id={'Auto.switch.Save'} tagName="span" />
      </Button>
    </>
  )
}

export default AutoSwitchRightbar
