import React, { FC, useEffect, useState, CSSProperties } from 'react'

interface MyCustomCSS extends CSSProperties {
  '--size': string
  '--borderColor': string
  '--color': string
  '--sizeChecked': string
}

export const AppCheckboxCustom: FC<any> = ({ checked: checkedProp, disabled, onChange, onFocus, onBlur, text, size, borderColor, color, sizeChecked }) => {
  const [checked, setChecked] = useState(checkedProp)

  useEffect(() => {
    setChecked(checkedProp)
  }, [checkedProp])

  const handleClick = () => {
    if (disabled) {
      return
    }

    if (onChange) {
      onChange({ [text]: !checked })
    }
    setChecked(!checked)
  }

  const handleFocus = () => {
    if (onFocus) {
      onFocus()
    }
  }

  const handleBlur = () => {
    if (onBlur) {
      onBlur()
    }
  }

  const someStyle = {
    '--size': size,
    '--borderColor': borderColor,
    '--color': color,
    '--sizeChecked': sizeChecked,
  }

  return (
    <div className="d-flex align-items-center text-center">
      <div onClick={handleClick} className="d-flex align-items-center text-center m-0">
        <input type="radio" checked={checkedProp} disabled={disabled} onFocus={handleFocus} onBlur={handleBlur} style={someStyle as MyCustomCSS} />
        <label htmlFor="radio" id="text-radio" className="text-radio-class align-items-center d-flex text-center  m-0 ml-2 mr-3">
          <span className={checkedProp ? 'color-checked' : 'color-checked-hov'}>{text}</span>
        </label>
      </div>
    </div>
  )
}
